import React, { useReducer } from 'react'
import { CONTACT_FORMS } from '../../constants/collections'
import {
  fetchCollection as firestoreFetchCollection,
  updateDocument as firestoreUpdateDocument
} from '../../services/firebase'
import { SET_CONTACT_FORMS } from '../types'
import contactReducer from './contactReducer'

const ContactContext = React.createContext()

const ContactState = ({ children }) => {

  const initialState = {
    contactForms: [],
    contactFormsLoaded: false,
  }

  const [state, dispatch] = useReducer(contactReducer, initialState)

  const COLLECTION_FOR_TYPE = {
    contactForms: CONTACT_FORMS
  }

  const SETTER_FOR_TYPE = {
    contactForms: SET_CONTACT_FORMS,
  }

  const fetchCollection = async (type) => {
    const snapshot = await firestoreFetchCollection(COLLECTION_FOR_TYPE[type])
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    dispatch({
      type: SETTER_FOR_TYPE[type],
      payload: array
    })
  }

  // const createDocument = async (type, data) => {
  //   try {
  //     const snapshot = await firestoreCreateDocument(COLLECTION_FOR_TYPE[type], data)
  //     dispatch({
  //       type: SETTER_FOR_TYPE[type],
  //       payload: [
  //         { ...data, id: snapshot.id },
  //         ...state[type]
  //       ]
  //     })
  //     return { id: snapshot.id }
  //   } catch(err) {
  //     console.log(err)
  //     return { error: err }
  //   }
  // }

  const updateDocument = async (type, id, data) => {
    try {
      await firestoreUpdateDocument(`${COLLECTION_FOR_TYPE[type]}/${id}`, data)
      let array = [...state[type]]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data
      }
      dispatch({
        type: SETTER_FOR_TYPE[type],
        payload: array
      })
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  // const deleteDocument = async (type, id) => {
  //   try {
  //     await firestoreDeleteDocument(`${COLLECTION_FOR_TYPE[type]}/${id}`)
  //     let array = [...state[type]]
  //     let index = array.findIndex(s => s.id === id)
  //     array.splice(index, 1)
  //     dispatch({
  //       type: SETTER_FOR_TYPE[type],
  //       payload: array
  //     })
  //     return {}
  //   } catch(err) {
  //     console.log(err)
  //     return { error: err }
  //   }
  // }

  return (
    <ContactContext.Provider value={{
      contactForms: state.contactForms,
      contactFormsLoaded: state.contactFormsLoaded,
      fetchContactForms: () => fetchCollection('contactForms'),
      updateContactForm: (id, data) => updateDocument('contactForms', id, data),
    }}>
      { children }
    </ContactContext.Provider>
  )
}

export { ContactContext }

export default ContactState