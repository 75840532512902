import { SET_CONTACT_FORMS } from '../types'

const contactReducer = (state, action) => {
  switch(action.type) {
    case SET_CONTACT_FORMS:
      return {
        ...state,
        contactForms: action.payload,
        contactFormsLoaded: true
      }
    default:
      return state
  }
}

export default contactReducer