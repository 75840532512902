import { useState } from "react"

const ImageInput = ({ onImageSelected = () => { alert("onImageSelected not implemented")}, imageUrl = '', onRemove = () => { alert("onRemove not implemented" )}, displayMode = "cover", width = 400, height = 200 }) => {

  const [image, setImage] = useState(null)
  const handleImageChange = (e) => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      onImageSelected({ name: file.name, type: file.type, size: file.size, base64: reader.result})
      setImage({ name: file.name, type: file.type, size: file.size, base64: reader.result})
    }
    reader.readAsDataURL(file)
  }

  const handleRemove = () => {
    if(image) {
      setImage(null)
    }
    onRemove()
  }

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-row'>
        <div className='image-input image-input-outline image-input-empty image-input-outline-2' data-kt-image-input='true'>
          <div className={`image-input-wrapper w-${imageUrl ? width : '250'}px h-${imageUrl ? height : '125'}px bgi-position-y-center bgi-position-x-center bgi-size-${displayMode}`} style={{backgroundImage: `url(${imageUrl})`}}></div>
          { !imageUrl && <label className='image-input-tooltip'>Select image</label> }
          <label className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow' data-kt-image-input-action='change' data-bs-toggle='tooltip' title='' data-bs-original-title='Change image'>
            <i className='bi bi-pencil-fill fs-7'></i>
            <input type='file' name='image' accept='.png, .jpg, .jpeg' onChange={handleImageChange} />
            <input type='hidden' name='profile_avatar_remove' />
          </label>
          { (imageUrl || image) && <label className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow' onClick={handleRemove} data-kt-image-input-action='remove' data-bs-toggle='tooltip' title='' data-bs-original-title='Remove fole'>
            <i className='bi bi-trash-fill fs-7'></i>
          </label> }
        </div>
      </div>
    </div>
  )
}

export default ImageInput