import React, { Component } from "react";

import { RichUtils } from "draft-js";

import Modal from "../pages/Articles/Modal";


class LinkSource extends Component {

  componentDidMount() {
    const { editorState, entityType, onComplete } = this.props

    const url = window.prompt("Link URL")

    if (url) {
      const data = {
        url: url.replace(/\s/g, ""),
      };
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        entityType.type,
        "IMMUTABLE",
        data,
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const nextState = RichUtils.toggleLink(
        editorState,
        editorState.getSelection(),
        entityKey,
      );
  
      onComplete(nextState);
    } else {
      onComplete(editorState)
    }
  }

  render() {
    return null
  }

  // render() {
    // const { textDirectionality } = this.props;
    // const { url } = this.state;
    // return (
    //   // <Modal
    //   //   onRequestClose={this.onRequestClose}
    //   //   onAfterOpen={this.onAfterOpen}
    //   //   isOpen
    //   //   contentLabel="Link chooser"
    //   // >
    //   <div style={{ zIndex: 9999}}>
    //     <form
    //       dir={textDirectionality === "RTL" ? "rtl" : undefined}
    //       className="LinkSource"
    //       onSubmit={this.onConfirm}
    //     >
    //       <label className="form-field">
    //         <span className="form-field__label">Link URL</span>
    //         <input
    //           ref={(inputRef) => {
    //             this.inputRef = inputRef;
    //           }}
    //           type="text"
    //           onChange={this.onChangeURL}
    //           value={url}
    //           placeholder="www.example.com"
    //         />
    //       </label>

    //       <button type="submit">Save</button>
    //     </form>
    //     </div>
    //   // </Modal>
    // );
  // }
}

export default LinkSource;