import React from "react"
import { DraftailEditor, BLOCK_TYPE, INLINE_STYLE, ENTITY_TYPE } from "draftail"
import { convertToRaw, convertFromRaw, AtomicBlockUtils, CompositeDecorator, RichUtils, Modifier } from "draft-js"
import { convertFromHTML, convertToHTML } from "draft-convert"
import "draft-js/dist/Draft.css"
import "draftail/dist/draftail.css"
import LinkSource from "./LinkSource"
import { uploadFile } from "../services/firebase"
import { Buffer } from "buffer"
import config from '../../config.json'

const modalsRoot = document.getElementById('root-modals') || document.body

const ENTITY_TYPE_ATTACHMENT = "ATTACHMENT"
const ENTITY_TYPE_BUTTON = "BUTTON"

const importerConfig = {
  htmlToEntity: (nodeName, node, createEntity) => {
    // a tags will become LINK entities, marked as mutable, with only the URL as data.
    if (nodeName === "a") {

      let url = (node.href || '').replace(window.location.origin, '')

      if(node.className === 'articleButton') {
        return createEntity(ENTITY_TYPE_BUTTON, "MUTABLE", {
          target: url,
          title: node.textContent
        })
      }

      return createEntity(ENTITY_TYPE.LINK, "MUTABLE", {
        url: url,
        text: node.textContent
      })
    }

    if (nodeName === "img") {
      return createEntity(ENTITY_TYPE.IMAGE, "MUTABLE", {
        src: node.src,
        alt: node.alt
      })
    }

    return null
  },
  htmlToBlock: (nodeName, node) => {
    if (nodeName === "img") {
      // "atomic" blocks is how Draft.js structures block-level entities.
      return "atomic"
    } else if(nodeName === 'a' && node.className === 'articleButton') {
      return "atomic"
    }

    return null
  },
}

const domainForEnvironment = {
  "development": "assurgliss-development.web.app",
  "staging": "assurgliss-staging.web.app",
  "production": "assuranceski.com"
}

const isInternalLink = (url) => {
  return url[0] === '/' || url.toLowerCase().includes(domainForEnvironment[config.environment])
}

const exporterConfig = {
  blockToHTML: (block) => {
    if (block.type === BLOCK_TYPE.BLOCKQUOTE) {
      return <blockquote />
    }

    // Discard atomic blocks, as they get converted based on their entity.
    if (block.type === BLOCK_TYPE.ATOMIC) {
      return {
        start: "",
        end: "",
      }
    }

    return null
  },

  entityToHTML: (entity, originalText) => {
    if (entity.type === ENTITY_TYPE.LINK) {
      const target = isInternalLink(entity.data.url) ? '_self' : '_blank'
      return <a href={entity.data.url} target={target} rel="noreferrer">{originalText}</a>
    }

    if (entity.type === ENTITY_TYPE.IMAGE) {
      return <img src={entity.data.src} alt={entity.data.alt} />
    }

    if (entity.type === ENTITY_TYPE.HORIZONTAL_RULE) {
      return <hr />
    }

    if (entity.type === ENTITY_TYPE_BUTTON) {
      const target = isInternalLink(entity.data.target) ? '_self' : '_blank'
      return <a className="articleButton" href={entity.data.target} target={target} rel="noreferrer">{entity.data.title}</a>
    }


    return originalText
  },
}

export const fromHTML = (html) => {
  const raw = convertFromHTML(importerConfig)(html)
  return convertToRaw(raw)
}

export const toHTML = (raw) => raw ? convertToHTML(exporterConfig)(convertFromRaw(raw)) : ""

const ImageSource = ({ editorState, entityType, onComplete, onClose }) => {

  let sourceUrl = ''
  let altText = ''

  const handleClickAway = (root) => {
    onComplete(editorState)
    modalsRoot.removeChild(root)
  }

  const handleSubmit = (root) => {
    const content = editorState.getCurrentContent()
    const contentWithEntity = content.createEntity(
      entityType.type,
      "IMMUTABLE",
      { alt: altText, src: sourceUrl },
    )
    const entityKey = contentWithEntity.getLastCreatedEntityKey()
    const nextState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " ",
    )

    onComplete(nextState)
    modalsRoot.removeChild(root)
  }

  const modalRoot = document.createElement('div')
  modalRoot.className = 'source-modal-root'
  
  const background = document.createElement('div')
  background.className = "source-modal-background"
  background.addEventListener('click', () => handleClickAway(modalRoot))

  
  const modal = document.createElement('div')
  modal.className = "source-modal-body"
  
  const modalTitle = document.createElement('h5')
  modalTitle.textContent = "Insert image"
  
  const altInput = document.createElement('input')
  altInput.addEventListener('change', e => {
    altText = e.target.value
  })
  altInput.placeholder = "Enter image alt text"
  
  const urlInput = document.createElement('input')
  urlInput.addEventListener('change', e => {
    sourceUrl = e.target.value
  })
  urlInput.placeholder = "Enter image source url"

  const label = document.createElement('span')
  label.textContent = "or upload file"

  const uploadButton = document.createElement('button')
  uploadButton.textContent = "Upload file"
  uploadButton.className = "primary"
  
  const uploadInput = document.createElement('input')
  uploadInput.type = 'file'
  uploadInput.accept="image/png, image/jpeg"
  uploadInput.addEventListener('change', e => {
    const file = e.target.files[0]
    if(!file) return

    var reader = new FileReader();
    reader.onload = async function () {
      const uploadResponse = await uploadFile({ file: { name: file.name, type: file.type, size: file.size, base64: Buffer.from(this.result, 'binary').toString('base64')}})
      if(uploadResponse.url) {
        sourceUrl = uploadResponse.url
        handleSubmit(modalRoot)
      }
    }
    reader.readAsBinaryString(file);
    handleClickAway(modalRoot)
  })
  
  uploadButton.addEventListener('click', () => {
    uploadInput.click()
  })

  const closeButton = document.createElement('button')
  closeButton.textContent = "Cancel"
  closeButton.addEventListener('click', () => {
    handleClickAway(modalRoot)
  })

  const submitButton = document.createElement('button')
  submitButton.className = "submit"
  submitButton.textContent = "Insert"
  submitButton.addEventListener('click', () => {
    handleSubmit(modalRoot)
  })

  modal.appendChild(modalTitle)
  modal.appendChild(altInput)
  modal.appendChild(urlInput)
  modal.appendChild(label)
  modal.appendChild(uploadButton)
  modal.appendChild(closeButton)
  modal.appendChild(submitButton)

  modalRoot.appendChild(background)
  modalRoot.appendChild(modal)

  modalsRoot.appendChild(modalRoot)

  return null
}

const AttachmentSource = ({ editorState, entityType, onComplete, onClose }) => {

  let sourceUrl = ''
  // let linkText = ''

  const selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentBlock = currentContent.getBlockForKey(anchorKey);
  
  //Then based on the docs for SelectionState -
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();
  const selectedText = currentBlock.getText().slice(start, end);

  if(!selectedText) {
    alert("Select text which should serve as link")
    onClose()
    return
  }
  
  // linkText = selectedText || ''
  
  // const fragment = getContentStateFragment(contentState, selectionState);
  // const plainText = fragment.map((block) => block.getText()).join(delimiter);


  const handleClickAway = (root) => {
    onComplete(editorState)
    modalsRoot.removeChild(root)
  }

  const handleSubmit = (root) => {
    let content = editorState.getCurrentContent()
    const contentWithEntity = content.createEntity(
      ENTITY_TYPE.LINK,
      "IMMUTABLE",
      { url: sourceUrl },
    )
    const entityKey = contentWithEntity.getLastCreatedEntityKey()
    let nextState

    nextState = RichUtils.toggleLink(
      editorState,
      selection,
      entityKey,
    );

    onComplete(nextState)
    modalsRoot.removeChild(root)
  }

  const modalRoot = document.createElement('div')
  modalRoot.className = 'source-modal-root'
  
  const background = document.createElement('div')
  background.className = "source-modal-background"
  background.addEventListener('click', () => handleClickAway(modalRoot))

  
  const modal = document.createElement('div')
  modal.className = "source-modal-body"
  
  const modalTitle = document.createElement('h5')
  modalTitle.textContent = "Link PDF"
  
  // const textInput = document.createElement('input')
  // textInput.value = linkText
  // textInput.addEventListener('change', e => {
  //   linkText = e.target.value
  // })
  // textInput.placeholder = "Enter link text"
  // textInput.disabled = Boolean(selectedText)
  
  const urlInput = document.createElement('input')
  urlInput.addEventListener('change', e => {
    sourceUrl = e.target.value
  })
  urlInput.placeholder = "Enter PDF url"

  const label = document.createElement('span')
  label.textContent = "or upload file"

  const uploadButton = document.createElement('button')
  uploadButton.textContent = "Upload file"
  uploadButton.className = "primary"
  
  const uploadInput = document.createElement('input')
  uploadInput.type = 'file'
  uploadInput.accept="application/pdf"
  uploadInput.addEventListener('change', e => {
    const file = e.target.files[0]
    if(!file) return
    var reader = new FileReader();
    reader.onload = async function () {
      const uploadResponse = await uploadFile({ file: { name: file.name, type: file.type, size: file.size, base64: Buffer.from(this.result, 'binary').toString('base64')}})
      if(uploadResponse.url) {
        sourceUrl = uploadResponse.url
        // handleSubmit(modalRoot)
        urlInput.value = sourceUrl
        uploadButton.textContent = "Change file"
      }
    }
    reader.readAsBinaryString(file);
  })
  
  uploadButton.addEventListener('click', () => {
    uploadInput.click()
  })

  const closeButton = document.createElement('button')
  closeButton.textContent = "Cancel"
  closeButton.addEventListener('click', () => {
    handleClickAway(modalRoot)
  })

  const submitButton = document.createElement('button')
  submitButton.className = "submit"
  submitButton.textContent = "Insert"
  submitButton.addEventListener('click', () => {
    handleSubmit(modalRoot)
  })

  modal.appendChild(modalTitle)
  // modal.appendChild(textInput)
  modal.appendChild(urlInput)
  modal.appendChild(label)
  modal.appendChild(uploadButton)
  modal.appendChild(closeButton)
  modal.appendChild(submitButton)

  modalRoot.appendChild(background)
  modalRoot.appendChild(modal)

  modalsRoot.appendChild(modalRoot)

  return null
}

class ImageBlock extends React.Component {
  render() {
    const { blockProps } = this.props
    const { entity } = blockProps
    const { src, alt } = entity.getData()

    return <img className="editor-image" src={src} alt={alt} width="256" />
  }
}

  
const ButtonSource = ({ editorState, entityType, onComplete, onClose }) => {

  let buttonTitle = ''
  let buttonTarget = ''

  const handleClickAway = (root) => {
    onComplete(editorState)
    modalsRoot.removeChild(root)
  }

  const handleSubmit = (root) => {
    const content = editorState.getCurrentContent()
    const contentWithEntity = content.createEntity(
      entityType.type,
      "IMMUTABLE",
      { title: buttonTitle, target: buttonTarget },
    )
    const entityKey = contentWithEntity.getLastCreatedEntityKey()
    const nextState = AtomicBlockUtils.insertAtomicBlock(
      editorState,
      entityKey,
      " ",
    )

    onComplete(nextState)
    try {
      modalsRoot.removeChild(root)
    } catch(e) {}
  }

  const modalRoot = document.createElement('div')
  modalRoot.className = 'source-modal-root'
  
  const background = document.createElement('div')
  background.className = "source-modal-background"
  background.addEventListener('click', () => handleClickAway(modalRoot))

  
  const modal = document.createElement('div')
  modal.className = "source-modal-body"
  
  const modalTitle = document.createElement('h5')
  modalTitle.textContent = "Insert button"
  
  const titleInput = document.createElement('input')
  titleInput.addEventListener('change', e => {
    buttonTitle = e.target.value
  })
  titleInput.placeholder = "Enter button label"
  
  const targetInput = document.createElement('input')
  targetInput.addEventListener('change', e => {
    buttonTarget = e.target.value
  })
  targetInput.placeholder = "Enter button target url"

  const closeButton = document.createElement('button')
  closeButton.textContent = "Cancel"
  closeButton.addEventListener('click', () => {
    handleClickAway(modalRoot)
  })

  const submitButton = document.createElement('button')
  submitButton.className = "submit"
  submitButton.textContent = "Insert"
  submitButton.addEventListener('click', () => {
    handleSubmit(modalRoot)
  })

  modal.appendChild(modalTitle)
  modal.appendChild(titleInput)
  modal.appendChild(targetInput)
  modal.appendChild(closeButton)
  modal.appendChild(submitButton)

  modalRoot.appendChild(background)
  modalRoot.appendChild(modal)

  modalsRoot.appendChild(modalRoot)

  return null
}

class ButtonBlock extends React.Component {
  render() {
    const { blockProps } = this.props
    const { entity } = blockProps
    const { title } = entity.getData()

    return <button className='editor-button' onClick={e => e.preventDefault()}>{ title }</button>
  }
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

class LinkDecorator extends React.Component {
  render() {
    const { entityKey, contentState, children } = this.props
    const entity = contentState.getEntity(entityKey)
    const { url, text } = entity.getData()

    let link = url
    if(url[0] === '/') {
      link = `https://${domainForEnvironment[config.environment]}${url}`
    }
    const handleClick = () => {
      // todo show modal to change link      
      window.open(link, '_blank').focus()
    }
  
    return (
      <div className="tooltipCustom">
        <span className="editor-link" onClick={handleClick}>
          {children}
        </span>
        <span className="tooltipCustomText">{url}</span>
      </div>
    )
  }
}

export const customDecorators = new CompositeDecorator([{
  strategy: findLinkEntities,
  component: LinkDecorator,
}])

const entityTypeDetails = {
  link: { 
    type: ENTITY_TYPE.LINK,
    source: LinkSource,
    attributes: ['url'],
  },
  image: {
    type: ENTITY_TYPE.IMAGE,
    // Preserve the src and alt attributes and no other.
    attributes: ['src', 'alt'],
    // Preserve images for which the src starts with "http".
    whitelist: {
      src: '^http',
    },
    source: ImageSource,
    block: ImageBlock,
  },
  button: {
    type: ENTITY_TYPE_BUTTON,
    icon: <span className="Draftail-ToolbarButton__label" aria-hidden >▶️</span>,
    // Preserve the src and alt attributes and no other.
    attributes: ['target', 'title'],
    source: ButtonSource,
    block: ButtonBlock,
  },
  attachment: {
    type: ENTITY_TYPE_ATTACHMENT,
    icon: <span className="Draftail-ToolbarButton__label" aria-hidden >📎</span>,
    source: AttachmentSource,
  },
}

const Editor = ({ editorState, onChange, entities = [] }) => {
  return (
    <DraftailEditor
      editorState={editorState}
      onChange={onChange}
      blockTypes={[
        { type: BLOCK_TYPE.HEADER_ONE },
        { type: BLOCK_TYPE.HEADER_TWO },
        { type: BLOCK_TYPE.HEADER_THREE },
        { type: BLOCK_TYPE.HEADER_FOUR },
        { type: BLOCK_TYPE.HEADER_FIVE },
        { type: BLOCK_TYPE.HEADER_SIX },
        { type: BLOCK_TYPE.ORDERED_LIST_ITEM },
        { type: BLOCK_TYPE.UNORDERED_LIST_ITEM },
      ]}
      inlineStyles={[
        { type: INLINE_STYLE.BOLD },
        { type: INLINE_STYLE.ITALIC },
        { type: INLINE_STYLE.UNDERLINE },
        { type: INLINE_STYLE.CODE },
        { type: INLINE_STYLE.STRIKETHROUGH },
        { type: INLINE_STYLE.SUPERSCRIPT },
        { type: INLINE_STYLE.SUBSCRIPT },
      ]}
      entityTypes={entities.map(entity => entityTypeDetails[entity])}                  
    />
  )
}

export default Editor