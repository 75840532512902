/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useContext, forwardRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers';
import moment from 'moment';
// import {defaultCreateAppData, ICreateAppData} from '../'
// import {StepperComponent} from '../../../assets/ts/components'
// import {KTSVG} from '../../../helpers'
// import {Step1} from './steps/Step1'
// import {Step2} from './steps/Step2'
// import {Step3} from './steps/Step3'
// import {Step4} from './steps/Step4'
// import {Step5} from './steps/Step5'

const modalsRoot = document.getElementById('root-modals') || document.body

const ContactFormDetailModal = ({show, handleClose, contactForm, setContactForm, onSubmit}) => {
  
  const updateData = (fieldsToUpdate) => {
    const updatedData = {...contactForm, ...fieldsToUpdate}
    setContactForm(updatedData)
  }

  const dateStringToDate = (dateString) => {
    if(!dateString) {
      return
      return new Date()
    }
    const components = dateString.split('/')
    return new Date(2000, parseInt(components[1]) - 1, parseInt(components[0]), 0, 0, 0, 0);
  }

  const dateToString = (date) => {
    if(!date) {
      return ''
    }
    return `${date.getDate()}/${date.getMonth() + 1}`
  }

  const onSave = async () => {
    onSubmit(contactForm)
  }    

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{ 'Contact form detail' }</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          {/*begin::Form */}
          <form noValidate id='kt_modal_create_app_form'>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>
                  Sender name
                </span>
              </label>
              <div className='fv-row mb-10 contact-form-field'>
                { contactForm?.name}
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>
                  Sender email
                </span>
              </label>
              <div className='fv-row mb-10 contact-form-field'>
                <a href={`mailto:${contactForm?.email}?subject=Assuranceski.com assistance&body=Dear ${contactForm?.name},`} target='_blank' rel='noreferrer'>{ contactForm?.email}</a>
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>
                  Date sent
                </span>
              </label>
              <div className='fv-row mb-10 contact-form-field'>
                { moment(contactForm?.meta?.created).format('DD/MM/YYYY HH:mm') }
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>
                  Message
                </span>
              </label>
              <div className='fv-row mb-10 contact-form-field'>
                { contactForm?.message}
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2 d-row gap-4'>
                <span>Resolved</span>
                <input className='form-check-input' type='checkbox' checked={contactForm?.resolved} onChange={e => updateData({
                  resolved: e.target.checked,
                })} />
              </label>
            </div>

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
              <div className='me-2'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
              </div>                
              <div className='d-flex gap-3'>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={onSave}
                >
                  { 'Update' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}


export default ContactFormDetailModal
