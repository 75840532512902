/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useContext, forwardRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { ProductsContext } from '../../context'
import DatePicker, { CalendarContainer } from "react-datepicker";
import { EditorState } from "draft-js"

import { createEditorStateFromRaw, serialiseEditorStateToRaw } from "draftail"
import Editor, { customDecorators, fromHTML, toHTML } from '../../components/Editor'

import "react-datepicker/dist/react-datepicker.css";
// import {defaultCreateAppData, ICreateAppData} from '../'
// import {StepperComponent} from '../../../assets/ts/components'
// import {KTSVG} from '../../../helpers'
// import {Step1} from './steps/Step1'
// import {Step2} from './steps/Step2'
// import {Step3} from './steps/Step3'
// import {Step4} from './steps/Step4'
// import {Step5} from './steps/Step5'

const modalsRoot = document.getElementById('root-modals') || document.body

const NewsDetailModal = ({show, handleClose, news, setNews, onSubmit, onDelete}) => {


  const [selectedLanguage, setSelectedLanguage] = useState('fr')

  const [editorStates, setEditorStates] = useState({
    en: EditorState.createEmpty(),
    fr: EditorState.createEmpty(),
    it: EditorState.createEmpty(),
    es: EditorState.createEmpty()
  })

  useEffect(() => {
    if(!news) {
      return
    }
    setEditorStates({
      en: createEditorStateFromRaw(fromHTML(news.text?.en || ''), customDecorators),
      fr: createEditorStateFromRaw(fromHTML(news.text?.fr || ''), customDecorators),
      it: createEditorStateFromRaw(fromHTML(news.text?.it || ''), customDecorators),
      es: createEditorStateFromRaw(fromHTML(news.text?.es || ''), customDecorators)
    })
  }, [news])

  const handleEditorChange = (content) => {
    setEditorStates({
      ...editorStates,
      [selectedLanguage]: content
    })
  }

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...news, ...fieldsToUpdate}
    setNews(updatedData)
  }

  const dateStringToDate = (dateString) => {
    if(!dateString) {
      return
      return new Date()
    }
    const components = dateString.split('/')
    return new Date(2000, parseInt(components[1]) - 1, parseInt(components[0]), 0, 0, 0, 0);
  }

  const dateToString = (date) => {
    if(!date) {
      return ''
    }
    return `${date.getDate()}/${date.getMonth() + 1}`
  }

  const onSave = () => {
    const newsItem = {...news}
    newsItem.text = {
      en: toHTML(serialiseEditorStateToRaw(editorStates.en)),
      fr: toHTML(serialiseEditorStateToRaw(editorStates.fr)),
      it: toHTML(serialiseEditorStateToRaw(editorStates.it)),
      es: toHTML(serialiseEditorStateToRaw(editorStates.es)),
    }
    onSubmit(newsItem)
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{ news?.id ? 'Update News Item' : 'Create News Item' }</h2>
        {/* begin::Close */}
        {/* <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div> */}
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          <div className='mb-5'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Language</span>
            </label>
            <div className='nav-group nav-group-outline nav-group-slim'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='fr'
                  checked={selectedLanguage === 'fr'}
                  onChange={(e) => setSelectedLanguage('fr')}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  FR
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  EN
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  ES
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  IT
                </span>
              </label>
            </div>
          </div>
          {/*begin::Form */}
          <form noValidate id='kt_modal_create_app_form'>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>News title</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='news-title'
                placeholder=''
                value={news?.title?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    title: {
                      ...(news?.title || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>
                  Text
                { selectedLanguage !== "fr" && <><span>&nbsp;&nbsp;&nbsp;</span>
                  {/* <Button onClick={handleCopyContent} size="sm" className="ml-5">{'Copy from FR'}</Button> */}
                  </> }
                  {/* <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleExportContent} size="sm" className="ml-5">{'Export to HTML'}</Button></>
                  <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleImportContent} size="sm" className="ml-5">{'Import from HTML'}</Button></> */}
                </span>
                {/* <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your unique article title'
                ></i> */}
              </label>
              { news && <div>
                <Editor
                  editorState={editorStates[selectedLanguage]}
                  onChange={handleEditorChange} />
                </div>
                }
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Link URL</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='news-url'
                placeholder=''
                value={news?.link?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    link: {
                      ...(news?.link || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>
            
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2 d-row gap-4'>
                <span>Published</span>
                <input className='form-check-input' type='checkbox' checked={news?.published} onChange={e => updateData({
                  published: e.target.checked,
                })} />
              </label>
            </div>

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
              <div className='me-2'>
                { news?.id &&
                  <button
                    type='button'
                    className='btn btn-lg btn-danger'
                    data-kt-stepper-action='next'
                    onClick={onDelete}
                  >
                    { 'Delete' }
                  </button>
                }
              </div>                
              <div className='d-flex gap-3'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={onSave}
                >
                  { news?.id ? 'Update' : 'Create' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

const ExampleCustomInput = forwardRef(({ value, onClick, disabled, placeholderText }, ref) => (
  <button 
    disabled={disabled}
    className='btn btn-light btn-active-primary px-6 py-3'
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
  >
    {value || placeholderText}
  </button>
));


const DPicker = ({ selected, onChange, disabled = false }) => {
  return (
    <DatePicker
      disabled={disabled}
      selected={selected}
      onChange={onChange}
      dateFormat="d. MMMM"
      showIcon
      showDayPicker
      placeholderText="Select a date"
      calendarContainer={
        ({ className, children }) => (
          <div className={`${className} bg-light rounded` } style={{zIndex: 9999}}>
            <CalendarContainer>
              <div className="position-relative">
                {children}
              </div>
            </CalendarContainer>
          </div>
        )
      }
      customInput={<ExampleCustomInput disabled={disabled} placeholderText="Select a date" />}
      renderCustomHeader={({
        date,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <button
            className='btn btn-sm'
            onClick={(e) => {
              e.preventDefault()
              decreaseMonth()
            }} 
            disabled={prevMonthButtonDisabled} >
            {"<"}
          </button>
          <div className='fw-bold'>
            {date.toLocaleString('default', { month: 'long' })}
          </div>

          <button 
            className='btn btn-sm'
            onClick={e => {
              e.preventDefault()
              increaseMonth()
            }}
            disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}

    />
  )
}


export default NewsDetailModal
