import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ContactContext } from '../../context'
import ContactFormDetail from './ContactFormDetail'
import moment from 'moment'

const ContactFormsPage = () => {
  
  const { contactForms, contactFormsLoaded, fetchContactForms, updateContactForm } = useContext(ContactContext)

  const [currentContactForm, setCurrentContactForm] = useState(null)
  const [sortBy, setSortBy] = useState('date')
  const [sortOrder, setSortOrder] = useState('desc')
  const [filteredContactForms, setFilteredContactForms] = useState([])
  // const [search, setSearch] = useState('')

  useEffect(() => {
    if(!contactFormsLoaded) {
      fetchContactForms()
    }
  }, [contactFormsLoaded, fetchContactForms])

  const onEditClick = (form) => {
    setCurrentContactForm(form)
  }

  const onDetailSubmit = async (data) => {
    
    await updateContactForm(data.id, data)
    setCurrentContactForm(null)
  }

  const onSortClick = (property) => {
    if(sortBy !== property) {
      setSortBy(property)
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {
    let r = [...contactForms]
    if(sortBy === 'sender') {
      r.sort((a, b) => {
        if(a.name < b.name) return sortOrder === 'asc' ? -1 : 1
        if(a.name > b.name) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'type') {
      r.sort((a, b) => {
        if(a.type < b.type) return sortOrder === 'asc' ? -1 : 1
        if(a.type > b.type) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'date') {
      r.sort((a, b) => {
        if(a.meta?.created < b.meta?.created) return sortOrder === 'asc' ? -1 : 1
        if(a.meta?.created > b.meta?.created) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'status') {
      r.sort((a, b) => {
        if(a.resolved < b.resolved) return sortOrder === 'asc' ? -1 : 1
        if(a.resolved > b.resolved) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    }
    // if(search) {
    //   let searchComponents = search.split(/\s/g).map(s => s.trim()).filter(s => s)
    //   r = r.filter(infoBlock => {
    //     let foundCount = 0
    //     for(let searchComponent of searchComponents) {
    //       if(infoBlock.title.fr.toLowerCase().includes(searchComponent.toLowerCase())) {
    //         foundCount++
    //       }
    //     }
    //     return foundCount === searchComponents.length
    //   })
    // }
    setFilteredContactForms(r)
  }, [contactForms, sortOrder, sortBy/*, search*/])

  return (
    <>
      <ToolbarCustom
        pageTitle={'Contact requests'}
        // filterElements={[
        // <div
        //   data-kt-search-element='form'
        //   className='w-100 position-relative'
        // >
        //   <KTSVG
        //     path='/media/icons/duotune/general/gen021.svg'
        //     className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
        //   />
        //   <input
        //     type='text'
        //     className='form-control form-control-flush ps-10'
        //     name='search'
        //     value={search}
        //     placeholder='Search...'
        //     data-kt-search-element='input'
        //     onChange={(e) => setSearch(e.target.value)}
        //   />
        // </div>]}
        loading={!contactFormsLoaded} />
      {/* begin::Row */}
      <div className='row g-6 g-xl-9 mt-1'>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-15px'>
              </th>
              <th onClick={() => onSortClick('sender')} className='min-w-150px'>{`Sender${sortBy === 'sender' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('type')} className='min-w-150px'>{`Origin${sortBy === 'type' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th className='min-w-150px'>Message</th>
              <th onClick={() => onSortClick('date')} className='min-w-150px'>{`Date${sortBy === 'date' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('status')} className='min-w-140px'>{`Status${sortBy === 'status' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th className='min-w-100px text-end'>Actions</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          { contactFormsLoaded && filteredContactForms.map((form) => 
            <tr key={`form_${form.id}`}>
              <td>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className="text-dark fw-bold fs-6">
                      { form.name }
                    </div>
                    <span className='text-muted fw-semibold fs-7'>{ form.email }</span>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className="text-muted fw-semibold fs-7">
                      { `${form.type === 'commercial' ? 'Service commercial' : 'Contact form' }` }
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className="text-muted fw-semibold fs-7">
                      { `${form.message.substring(0, 80).trim()}${form.message.length > 80 ? '...' : ''}` }
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    {/* <div className='text-dark fw-bold text-hover-primary fs-6'>
                      { moment(infoBlock.meta.updated).format("DD/MM/YYYY HH:mm") }
                    </div> */}
                    <div className='text-dark fw-semibold d-block fs-7'>
                      { form.meta?.created ? moment(form.meta.created).format("DD/MM/YYYY HH:mm") : 'Not available' }
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className={`text-${form.resolved ? 'success' : 'warning'} fw-semibold d-block fs-7`}>
                  { form.resolved ? 'Resolved' : 'Pending' }
                </div>                
              </td>
              <td>
                <div className='d-flex justify-content-end flex-shrink-0'>
                  {/* <button
                  onClick={() => togglePublish(infoBlock)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path={infoBlock.published ? '/media/icons/duotune/general/gen040.svg' : '/media/icons/duotune/general/gen043.svg'} className='svg-icon-3' />
                  </button> */}

                  <button
                  onClick={() => onEditClick(form)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3' />
                  </button>
                </div>
              </td>
            </tr>
          )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Professionals'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
      <ContactFormDetail
        show={Boolean(currentContactForm)}
        handleClose={() => setCurrentContactForm(null)}
        contactForm={currentContactForm}
        setContactForm={setCurrentContactForm}
        onSubmit={onDetailSubmit} />
    </>
  )
}

const ContactFormsWrapper = () => {
  return (
    <>
      <ContactFormsPage />
    </>
  )
}

export default ContactFormsWrapper
