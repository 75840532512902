import {useContext, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ContentContext } from '../../context'
import InfoBlockDetailModal from './InfoBlockDetail'
import moment from 'moment'

const InfoBlocksPage = () => {
  
  const { infoBlocks, infoBlocksLoaded, fetchInfoBlocks, createInfoBlock, updateInfoBlock, deleteInfoBlock } = useContext(ContentContext)

  const [currentInfoBlock, setCurrentInfoBlock] = useState(null)
  const [sortBy, setSortBy] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [filteredInfoBlocks, setFilteredInfoBlocks] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if(!infoBlocksLoaded) {
      fetchInfoBlocks()
    }
  }, [infoBlocksLoaded, fetchInfoBlocks])

  const onCreateClick = () => {
    setCurrentInfoBlock({
      id: '',
      title: {},
      text: {},
      button_label: {},
      button_link: {},
      published: false,
    })
  }

  const onEditClick = (infoBlock) => {
    setCurrentInfoBlock(infoBlock)
  }

  const onDetailSubmit = async (data) => {
    if(!data.id) {
      await createInfoBlock(data)
    } else {
      await updateInfoBlock(data.id, data)
    }
    setCurrentInfoBlock(null)
  }

  const onDetailDelete = async () => {
    if(!confirm(`Are you sure you want to delete this article? (${currentInfoBlock?.title?.fr || currentInfoBlock.id})`)) return
    await deleteInfoBlock(currentInfoBlock.id)
    setCurrentInfoBlock(null)
  }

  const onSortClick = (property) => {
    if(sortBy !== property) {
      setSortBy(property)
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {
    let r = [...infoBlocks]
    if(sortBy === 'title') {
      r.sort((a, b) => {
        if(a.title.fr < b.title.fr) return sortOrder === 'asc' ? -1 : 1
        if(a.title.fr > b.title.fr) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'updated') {
      r.sort((a, b) => {
        if(a.meta.updated < b.meta.updated) return sortOrder === 'asc' ? -1 : 1
        if(a.meta.updated > b.meta.updated) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'status') {
      r.sort((a, b) => {
        if(a.published < b.published) return sortOrder === 'asc' ? -1 : 1
        if(a.published > b.published) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    }
    if(search) {
      let searchComponents = search.split(/\s/g).map(s => s.trim()).filter(s => s)
      r = r.filter(infoBlock => {
        let foundCount = 0
        for(let searchComponent of searchComponents) {
          if(infoBlock.title.fr.toLowerCase().includes(searchComponent.toLowerCase())) {
            foundCount++
          }
        }
        return foundCount === searchComponents.length
      })
    }
    setFilteredInfoBlocks(r)
  }, [infoBlocks, sortOrder, sortBy, search])

  const togglePublish = async (infoBlock) => {
    await updateInfoBlock(infoBlock.id, { ...infoBlock, published: !infoBlock.published })
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Home page info blocks'}
        primaryButton={infoBlocksLoaded && { onClick: onCreateClick, title: 'Add' }}
        filterElements={[
        <div
          data-kt-search-element='form'
          className='w-100 position-relative'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
          />
          <input
            type='text'
            className='form-control form-control-flush ps-10'
            name='search'
            value={search}
            placeholder='Search...'
            data-kt-search-element='input'
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>]}
        loading={!infoBlocksLoaded} />
      <div className='row g-6 g-xl-9 mt-1'>
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-15px'>
              </th>
              <th onClick={() => onSortClick('title')} className='min-w-150px'>{`Title${sortBy === 'title' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('updated')} className='min-w-150px'>{`Updated${sortBy === 'updated' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('status')} className='min-w-140px'>{`Status${sortBy === 'status' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th className='min-w-100px text-end'>Actions</th>
            </tr>
          </thead>
          <tbody>
          { infoBlocksLoaded && filteredInfoBlocks.map((infoBlock) => 
            <tr key={`infoBlock_${infoBlock.id}`}>
              <td>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className={`${infoBlock.published ? 'text-primary' : 'text-dark'} fw-bold fs-6`}>
                      { infoBlock.title.fr }
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className='text-dark fw-semibold d-block fs-7'>
                      { moment(infoBlock.meta.updated).format("DD/MM/YYYY HH:mm") }
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className='text-dark fw-semibold d-block fs-7'>
                  { infoBlock.published ? 'Published' : 'Draft' }
                </div>                
              </td>
              <td>
                <div className='d-flex justify-content-end flex-shrink-0'>
                  <button
                  onClick={() => togglePublish(infoBlock)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path={infoBlock.published ? '/media/icons/duotune/general/gen040.svg' : '/media/icons/duotune/general/gen043.svg'} className='svg-icon-3' />
                  </button>

                  <button
                  onClick={() => onEditClick(infoBlock)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </button>
                </div>
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>

      <InfoBlockDetailModal
        show={Boolean(currentInfoBlock)}
        handleClose={() => setCurrentInfoBlock(null)}
        infoBlock={currentInfoBlock}
        setInfoBlock={setCurrentInfoBlock}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
    </>
  )
}

const InfoBlocksWrapper = () => {
  return (
    <>
      <InfoBlocksPage />
    </>
  )
}

export default InfoBlocksWrapper
