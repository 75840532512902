/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials'

const REDUCED_COUNT = 5

const TableChart = ({className, title = "", subtitle = "", data = [] }) => {

  const [showAll, setShowAll] = useState(false)
  
  const ratio = (count) => {
    // let total = 0
    // for(let item of data) {
    //   total += item.count
    // }
    // return count / (total / 100)
    const max = data[0].count
    return count / (max / 100)
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{ title }</span>
          <span className='text-muted fw-semibold fs-7'>{ subtitle }</span>
        </h3>
        <div className='card-toolbar'>
        { REDUCED_COUNT < data.length && <div onClick={() => setShowAll(!showAll)} className='btn btn-sm btn-outline'>{ showAll ? "Show only top" : "Show all" }</div> }
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-5'>
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className='p-0 min-w-200px'></th>
                <th className='p-0 min-w-100px'></th>
                <th className='p-0 min-w-40px'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              { (showAll ? data : data.slice(0, Math.min(data.length, REDUCED_COUNT))).map((item, i) =>
                <tr>
                  <td>
                    <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      { item.label }
                    </span>
                    {/* <span className='text-muted fw-semibold d-block fs-7'>Successful Fellas</span> */}
                  </td>
                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <span className='text-muted me-2 fs-7 fw-semibold'>{item.count}</span>
                      </div>
                      <div className='progress h-6px w-100'>
                        <div
                          className='progress-bar bg-primary'
                          role='progressbar'
                          style={{width: `${ratio(item.count)}%`}}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export default TableChart
