import moment from 'moment'
import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { CustomersContext, ProductsContext } from '../../context'
import PurchaseDetailModal from '../Purchases/PurchaseDetailModal'
import CustomerDetailModal from './CustomerDetailModal'

const CustomersPage = () => {
  
  const {
    customers,
    customersLoaded,
    fetchCustomers,
    purchases,
    purchasesLoaded,
    fetchPurchases
  } = useContext(CustomersContext)

  const {
    resorts,
    resortsLoaded,
    fetchResorts,
    domains,
    domainsLoaded,
    fetchDomains,
    durations,
    durationsLoaded,
    fetchDurations
  } = useContext(ProductsContext)

  const [currentCustomer, setCurrentCustomer] = useState(null)
  const [filteredCustomers, setFilteredCustomers] = useState([])
  const [validPurchases, setValidPurchases] = useState([])
  const [sortBy, setSortBy] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [search, setSearch] = useState('')
  const [currentPurchase, setCurrentPurchase] = useState(null)
  const [displayPurchaseOnly, setDisplayPurchaseOnly] = useState(true)

  const [validCustomers, setValidCustomers] = useState([])

  useEffect(() => {
    let vp = []
    for(let p of purchases) {
      if(p.status === 'paid' || p.status === 'refunded') {
        vp.push(p)
      }
    }
    setValidPurchases([...vp])
  }, [purchases])

  useEffect(() => {
    if(!customersLoaded) {
      fetchCustomers()
    }
  }, [customersLoaded, fetchCustomers])


  useEffect(() => {
    if(!purchasesLoaded) {
      fetchPurchases()
    }
  }, [purchasesLoaded, fetchPurchases])

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!domainsLoaded) {
      fetchDomains()
    }
  }, [domainsLoaded, fetchDomains])

  useEffect(() => {
    if(!durationsLoaded) {
      fetchDurations()
    }
  }, [durationsLoaded, fetchDurations])


  const onViewClick = (customer) => {
    setCurrentCustomer(customer)
  }

  const emailForCustomer = (customer) => {
    if(customer.email) {
      return customer.email
    }
    const customersPurchases = validPurchases.filter(p => p.user === customer.uid)
    if(customersPurchases.length > 0) {
      return customersPurchases[0].buyer.email
    }
    return ""
  }

  const nameForCustomer = (customer) => {
    if(customer.displayName) {
      return customer.displayName
    }
    const customersPurchases = validPurchases.filter(p => p.user === customer.uid)
    if(customersPurchases.length > 0) {
      return `${customersPurchases[0].buyer.firstname} ${customersPurchases[0].buyer.lastname}`
    }
    return ""
  }


  const onSortClick = (property) => {
    if(sortBy !== property) {
      setSortBy(property)
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {
    const emailForCustomer = (customer) => {
      if(customer.email) {
        return customer.email
      }
      const customersPurchases = validPurchases.filter(p => p.user === customer.uid)
      if(customersPurchases.length > 0) {
        return customersPurchases[0].buyer.email
      }
      return ""
    }
  
    let fc = []
    for(let c of validCustomers) {
      const customersPurchases = validPurchases.filter(p => p.user === c.uid)
      if(customersPurchases.length > 0) {
        let purchase = customersPurchases[0]
        c.firstname = purchase.buyer.firstname
        c.lastname = purchase.buyer.lastname
        c.purchasesCount = customersPurchases.length
        // if(!c.email) {
        //   c.email = purchase.buyer.email
        // }
        fc.push(c)
      } else {
        c.firstname = 'N/A'
        c.lastname = ''
        c.purchasesCount = 0
        fc.push(c)
      }
    }
    if(sortBy === 'name') {
      fc.sort((a, b) => {
        if(a.firstname?.toLowerCase() < b.firstname?.toLowerCase()) return sortOrder === 'asc' ? -1 : 1
        if(a.firstname?.toLowerCase() > b.firstname?.toLowerCase()) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'email') {
      fc.sort((a, b) => {
        if(emailForCustomer(a)?.toLowerCase() < emailForCustomer(b)?.toLowerCase()) return sortOrder === 'asc' ? -1 : 1
        if(emailForCustomer(a)?.toLowerCase() > emailForCustomer(b)?.toLowerCase()) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'account') {
      fc.sort((a, b) => {
        if(a.email && !b.email) return sortOrder === 'asc' ? -1 : 1
        if(!a.email && b.email) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'createdAt') {
      fc.sort((a, b) => {
        if(moment(a.metadata.creationTime).valueOf() < moment(b.metadata.creationTime).valueOf()) return sortOrder === 'asc' ? -1 : 1
        if(moment(a.metadata.creationTime).valueOf() > moment(b.metadata.creationTime).valueOf()) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'purchasesCount') {
      fc.sort((a, b) => {
        if(a.purchasesCount < b.purchasesCount) return sortOrder === 'asc' ? -1 : 1
        if(a.purchasesCount > b.purchasesCount) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    }
    if(search) {
      fc = fc.filter(customer => {
        if(customer.firstname.toLowerCase().includes(search.toLowerCase())) return true
        if(customer.lastname.toLowerCase().includes(search.toLowerCase())) return true
        if(emailForCustomer(customer)?.toLowerCase().includes(search.toLowerCase())) return true
        return false
      })
    }
    setFilteredCustomers(fc)
  }, [validCustomers, validPurchases, sortOrder, sortBy, search])

  useEffect(() => {

    let fc = []
    for(let c of customers) {
      const customersPurchases = validPurchases.filter(p => p.user === c.uid)
      if(customersPurchases.length > 0) {
        let purchase = customersPurchases[0]
        c.firstname = purchase.buyer.firstname
        c.lastname = purchase.buyer.lastname
        c.purchasesCount = customersPurchases.length
        // if(!c.email) {
        //   c.email = purchase.buyer.email
        // }
        fc.push(c)
      } else {
        c.firstname = 'N/A'
        c.lastname = ''
        c.purchasesCount = 0
        fc.push(c)
      }
    }
    setValidCustomers(fc)
  }, [customers, validPurchases])

  const handleExport = () => {
    let csv = 'Name;ID;Email;Date created;Signup complete;Number of purchases\n'
    for(let c of validCustomers) {
      csv += nameForCustomer(c)
      csv += ';'
      csv += c.uid
      csv += ';'
      csv += emailForCustomer(c)
      csv += ';'
      csv += c.metadata.creationTime
      csv += ';'
      csv += c.email ? 'YES' : 'NO'
      csv += ';'
      const customersPurchases = validPurchases.filter(p => p.user === c.uid)
      csv += customersPurchases.length
      csv += '\n'
    }
    const a = document.createElement('a')
    a.download = `Assuranceski-Customers-Export-${moment().format("DD/MM/YYYY")}.csv`
    a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv)
    a.click()
  }

  const formatCreatedAt = (createdAt) => {
    if(!createdAt) return ''
    return moment(createdAt).format('DD/MM/YYYY HH:mm')
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Users'}
        filterElements={[
          /* begin::only users with purchases switch */
        <div className='form-check form-switch form-check-custom form-check-solid position-relative'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_customer_display_purchase_only'
            checked={displayPurchaseOnly}
            onChange={(e) => setDisplayPurchaseOnly(e.target.checked)}
          />
          <label
            className='form-check-label fw-bold text-gray-600'
            htmlFor='kt_customer_display_purchase_only'
          >
            Show only users with purchases
          </label>
        </div>,
        <div
          data-kt-search-element='form'
          className='w-100 position-relative'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
          />
          <input
            type='text'
            className='form-control form-control-flush ps-10'
            name='search'
            value={search}
            placeholder='Search...'
            data-kt-search-element='input'
            onChange={(e) => setSearch(e.target.value)}
          />
          {/* clear search field */}
          {search && (
            <div
              className='btn btn-flush btn-active-color-primary position-absolute top-50 ms-0 translate-middle-y end-0'
              onClick={() => setSearch('')}
            >
              <KTSVG
                path='/media/icons/duotune/arrows/arr061.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500'
              />
            </div>
          )}
        </div>, <div
          className='position-relative'
        >
          <div className='btn btn-primary btn-sm' onClick={handleExport}>Export</div>
        </div>]}
        loading={!customersLoaded}
        />
      {/* begin::Row */}
      <div className='row g-6 g-xl-9 mt-1'>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-15px'>
              </th>
              <th onClick={() => onSortClick('name')} className='min-w-150px'>{`Name${sortBy === 'name' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('email')} className='min-w-140px'>{`Email${sortBy === 'email' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('account')} className='min-w-60px text-center'>{`Account created${sortBy === 'account' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('createdAt')} className='min-w-60px text-center'>{`Created at${sortBy === 'createdAt' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th onClick={() => onSortClick('purchasesCount')} className='min-w-60px text-center'>{`Purchases${sortBy === 'purchasesCount' ? sortOrder === 'desc' ? ' ↑' : ' ↓' : ''}`}</th>
              <th className='min-w-100px text-end'>Actions</th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          { customersLoaded && filteredCustomers.filter(c => displayPurchaseOnly ? c.purchasesCount > 0 : true).map((customer) => 
            <tr key={`customer_${customer.uid}`}>
              <td>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className='text-dark fw-bold text-hover-primary fs-6'>
                      { `${customer.firstname} ${customer.lastname}` }
                    </div>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      { customer.uid }
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                   { emailForCustomer(customer) }
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                   { customer.email ? 'YES' : 'NO' }
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                   { formatCreatedAt(customer.metadata.creationTime) }
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                  { customer.purchasesCount }
                </span>
              </td>
              <td>
                <div className='d-flex justify-content-end flex-shrink-0'>
                  <button
                  onClick={() => onViewClick(customer)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    Detail
                    {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
                  </button>
                  {/* <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-3'
                    />
                  </button> */}
                </div>
              </td>
            </tr>
          )}
          </tbody>
          {/* end::Table body */}
          {/* begin::Empty search results label */}
          {search && filteredCustomers.filter(c => displayPurchaseOnly ? c.purchasesCount > 0 : true).length === 0 && (
            <tr>
              <td className='text-center text-gray-600 fw-bold py-10' colSpan='6'>
                {`No customers ${displayPurchaseOnly ? 'with purchases' : ''} found for query "${search}"`}
              </td>
            </tr>
          )}
          {/* end::Empty search results label */}
        </table>
      </div>
      
      <CustomerDetailModal
        show={Boolean(currentCustomer)}
        handleClose={() => setCurrentCustomer(null)}
        customer={currentCustomer}
        onPurchaseSelected={p => setCurrentPurchase(p)} />
      
      <PurchaseDetailModal
        show={Boolean(currentPurchase)}
        handleClose={() => setCurrentPurchase(null)}
        purchase={currentPurchase} />
    </>
  )
}

const CustomersWrapper = () => {
  return (
    <>
      <CustomersPage />
    </>
  )
}

export default CustomersWrapper
