import React, { forwardRef, useEffect } from 'react'

const dateStringToDate = (dateString) => {
  if(!dateString) {
    return
  }
  const components = dateString.split('/')
  return new Date(2000, parseInt(components[1]) - 1, parseInt(components[0]), 0, 0, 0, 0)
}

const dateToString = (date) => {
  if(!date) {
    return ''
  }
  return `${date.getDate()}/${date.getMonth() + 1}`
}

const ExampleCustomInput = forwardRef(({ value, onClick, disabled, placeholderText }, ref) => (
  <button 
    disabled={disabled}
    className='btn btn-light btn-active-primary px-6 py-3'
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
    ref={ref}
  >
    {value || placeholderText}
  </button>
))

const DPicker = ({ selected, onChange, disabled = false }) => {
  // selected format - string - DD/MM
  // onChange format - function - (date: string DD/MM) => void
  // custom input with two dropdowns for day and month - year is ignored, February is always 29 days
  const [selectedMonth, setSelectedMonth] = React.useState(selected ? parseInt(selected.split('/')?.[1]) - 1 : '')
  const [selectedDay, setSelectedDay] = React.useState(selected ? parseInt(selected.split('/')?.[0]) : '')

  const dayOptionsLength = selectedMonth === 1 ? 29 : 30 + (selectedMonth % 2)

  useEffect(() => {
    if(!selected) return
    const [day, month] = selected.split('/').map(s => parseInt(s))
    setSelectedDay(day)
    setSelectedMonth(month - 1)
  }, [selected])

  useEffect(() => {
    if(!selectedDay || !selectedMonth) return
    onChange([selectedDay, selectedMonth + 1].map(s => s.toString().padStart(2, '0')).join('/'))
  }, [selectedMonth, selectedDay])

  useEffect(() => {
    if(selectedDay > dayOptionsLength) {
      setSelectedDay(dayOptionsLength)
    }
  }, [selectedMonth])

  return (
    <div className='form-group d-flex fv-row gap-1'>
      <select
        className='form-select form-select-solid'
        name="day"
        id="day"
        value={selectedDay}
        onChange={e => setSelectedDay(parseInt(e.target.value))}
        disabled={disabled}
      >
        <option disabled value="">{disabled ? "" : "Select day"}</option>
        {Array.from({ length: dayOptionsLength }, (_, i) => i + 1).map(day => (
          <option key={day} value={day}>{day}</option>
        ))}
      </select>
      <select
        className='form-select form-select-solid'
        name="month"
        id="month"
        value={selectedMonth}
        onChange={e => setSelectedMonth(parseInt(e.target.value))}
        disabled={disabled}
      >
        <option disabled value="">{disabled ? "" : "Select month"}</option>
        <option value={0}>January</option>
        <option value={1}>February</option>
        <option value={2}>March</option>
        <option value={3}>April</option>
        <option value={4}>May</option>
        <option value={5}>June</option>
        <option value={6}>July</option>
        <option value={7}>August</option>
        <option value={8}>September</option>
        <option value={9}>October</option>
        <option value={10}>November</option>
        <option value={11}>December</option>
      </select>
    </div>
  )
}

export default DPicker

export {
  dateStringToDate,
  dateToString
}
