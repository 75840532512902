import moment from 'moment'
import {useContext, useEffect, useState} from 'react'
import { useLocation } from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { CustomersContext, ProductsContext } from '../../context'
import CustomerDetailModal from '../Customers/CustomerDetailModal'
import PurchaseOrderDetailModal from './PurchaseOrderDetailModal'

const PurchaseOrdersPage = () => {
  
  const {
    customers,
    customersLoaded,
    fetchCustomers,
    purchaseOrders,
    purchaseOrdersLoaded,
    fetchPurchaseOrders,
  } = useContext(CustomersContext)

  const {
    resorts,
    resortsLoaded,
    fetchResorts,
    domains,
    domainsLoaded,
    fetchDomains,
    durations,
    durationsLoaded,
    fetchDurations,
    products,
    productsLoaded,
    fetchProducts,
  } = useContext(ProductsContext)

  const [currentOrder, setCurrentOrder] = useState(null)
  const [filteredOrders, setFilteredOrders] = useState([])
  const [sortBy, setSortBy] = useState('date')
  const [sortOrder, setSortOrder] = useState('asc')

  useEffect(() => {
    if(!customersLoaded) {
      fetchCustomers()
    }
  }, [customersLoaded, fetchCustomers])

  useEffect(() => {
    if(!purchaseOrdersLoaded) {
      fetchPurchaseOrders()
    }
  }, [purchaseOrdersLoaded, fetchPurchaseOrders])

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!domainsLoaded) {
      fetchDomains()
    }
  }, [domainsLoaded, fetchDomains])

  useEffect(() => {
    if(!durationsLoaded) {
      fetchDurations()
    }
  }, [durationsLoaded, fetchDurations])

  useEffect(() => {
    if(!productsLoaded) {
      fetchProducts()
    }
  }, [productsLoaded, fetchProducts])

  const onViewClick = (purchase) => {
    setCurrentOrder(purchase)
  }

  // const emailForCustomer = (customer) => {
  //   if(customer.email) {
  //     return customer.email
  //   }
  //   const customersPurchaseOrders = purchaseOrders.filter(p => p.user === customer.uid)
  //   if(customersPurchaseOrders.length > 0) {
  //     return customersPurchaseOrders[0].billing.email
  //   }
  //   return "Anonymous"
  // }

  const onSortClick = (property) => {
    if(sortBy !== property) {
      setSortBy(property)
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {

    const buyerName = (order) => {
      return `${order.billing.first_name} ${order.billing.last_name}`
    }
    let fp = [...purchaseOrders]
    if(sortBy === 'date') {
      fp.sort((a, b) => {
        const aDate = a.paid_at || a.meta?.updated || a.meta?.created || 0
        const bDate = b.paid_at || b.meta?.updated || b.meta?.created || 0
        if(aDate < bDate) return sortOrder === 'asc' ? 1 : -1
        if(aDate > bDate) return sortOrder === 'asc' ? -1 : 1
        return 0
      })
    } else if(sortBy === 'customer') {
      fp.sort((a, b) => {
        if(buyerName(a)?.toLowerCase() > buyerName(b)?.toLowerCase()) return sortOrder === 'asc' ? -1 : 1
        if(buyerName(a)?.toLowerCase() < buyerName(b)?.toLowerCase()) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'price') {
      fp.sort((a, b) => {
        if(a.amount > b.amount) return sortOrder === 'asc' ? -1 : 1
        if(a.amount < b.amount) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'product') {
      fp.sort((a, b) => {
        if(a.product_name > b.product_name) return sortOrder === 'asc' ? -1 : 1
        if(a.product_name < b.product_name) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    } else if(sortBy === 'status') {
      fp.sort((a, b) => {
        if(a.status > b.status) return sortOrder === 'asc' ? -1 : 1
        if(b.status > a.status) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    }
    // if(search) {
    //   fc = fc.filter(customer => {
    //     if(customer.firstname.toLowerCase().includes(search.toLowerCase())) return true
    //     if(customer.lastname.toLowerCase().includes(search.toLowerCase())) return true
    //     if(emailForCustomer(customer)?.toLowerCase().includes(search.toLowerCase())) return true
    //     return false
    //   })
    // }
    setFilteredOrders(fp)
  }, [customers, sortOrder, sortBy, purchaseOrders])

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  const formattedPrice = (price) => {
    return `€ ${parseFloat((price / 100)).toFixed(2)}`.replace('.', ',')
  }

  // const promptExport = () => {
  //   if(!productsLoaded || !resortsLoaded || !domainsLoaded || !durationsLoaded) {
  //     alert('Wait for data to be loaded. Please try again in a moment.')
  //     return
  //   }
  //   setShowExportModal(true)
  // }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Purchase orders'}
        filterElements={[
        <div
          className='w-100 position-relative'
        >
          {/* <div className='btn btn-primary btn-sm' onClick={promptExport}>Export</div> */}
        </div>]}
        loading={!purchaseOrdersLoaded}
        />
      {/* begin::Row */}
      <div className='row g-6 g-xl-9 mt-1'>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-15px'>
              </th>
              <th onClick={() => onSortClick('customer')} className='min-w-120px'>{`Customer${sortBy === 'customer' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th onClick={() => onSortClick('price')} className='min-w-120px'>{`Amount${sortBy === 'price' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th className='min-w-150px'>{`Reference`}</th>
              <th onClick={() => onSortClick('date')} className='min-w-200px text-center'>{`Date${sortBy === 'date' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th onClick={() => onSortClick('status')} className='min-w-80px text-center'>{`Status${sortBy === 'status' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th className='min-w-100px text-end'></th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          { purchaseOrdersLoaded && filteredOrders.map((purchase) => 
            <tr key={`purchase_${purchase.id}`}>
              <td>
              </td>
              {/* <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className='text-dark fw-bold text-hover-primary fs-6'>
                      { purchase.product_name }
                    </div>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      { `${itemWithId(resorts || [], purchase.resort)?.name?.fr || ''}, ${itemWithId(domains || [], purchase.domain)?.title?.fr || ''}, ${itemWithId(durations || [], purchase.duration)?.title?.fr || ''}` }
                    </span>
                  </div>
                </div>
              </td> */}
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                   { `${purchase.billing.first_name} ${purchase.billing.last_name}` }
                </span>
                <span className='text-muted fw-semibold text-muted d-block fs-9'>
                  { purchase.user }
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                   { `${(purchase.amount / 100).toFixed(2)}€` }
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                   { purchase.id }
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                  {/* { purchase.paid_at } */}
                   { purchase.paid_at ? moment(purchase.paid_at).format('DD/MM/YYYY HH:mm') : purchase.meta?.updated ? moment(purchase.meta.updated).format('DD/MM/YYYY HH:mm') : 'N/A' }
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                   {  purchase.status }
                </span>
              </td>
              <td>
                <div className='d-flex justify-content-end flex-shrink-0'>
                  <button
                  onClick={() => onViewClick(purchase)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    Detail
                    {/* <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' /> */}
                  </button>
                  {/* <button
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon-3'
                    />
                  </button> */}
                </div>
              </td>
            </tr>
          )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget20
            className='h-md-50 mb-5 mb-xl-10'
            description='Active Projects'
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          />
          <CardsWidget7
            className='h-md-50 mb-5 mb-xl-10'
            description='Professionals'
            icon={false}
            stats={357}
            labelColor='dark'
            textColor='gray-300'
          />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      <PurchaseOrderDetailModal
        show={Boolean(currentOrder)}
        handleClose={() => setCurrentOrder(null)}
        purchase={currentOrder} />
    </>
  )
}

const PurchaseOrdersWrapper = () => {
  return (
    <>
      <PurchaseOrdersPage />
    </>
  )
}

export default PurchaseOrdersWrapper
