import React, { useContext, useState } from 'react'
import config from '../../config.json'
import { Button, Modal, ModalBody, ModalHeader } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { createCollectionBackup, importCollection } from '../services/firebase'
import { NotificationsContext } from '../context/notifications/notificationsState'

const modalsRoot = document.getElementById('root-modals') || document.body

const ImportCollectionModal = ({ isOpen, onClose, collectionName, sourceEnvironment, onImportComplete }) => {

  const { toast } = useContext(NotificationsContext)

  const [importing, setImporting] = useState(false)
  const [importError, setImportError] = useState(null)
  const [creatingBackup, setCreatingBackup] = useState(false)
  const [importStrategy, setImportStrategy] = useState('merge') // merge or replace

  const handleImport = async () => {
    setImporting(true)
    setImportError(null)

    try {
      const importResult = await importCollection(collectionName, sourceEnvironment, config.environment, importStrategy)
      console.log("importResult", importResult)
      if(onImportComplete) {
        await onImportComplete()
      }
      onClose()
      if(importResult?.importedCount) {
        toast({ message: `${importResult.importedCount} documents successfully imported`, type: 'success' })
      }
    } catch (error) {
      console.log("import error", error)
      setImportError(error)
      alert(error.message)
    }

    setImporting(false)
  }

  const handleCreateBackup = async () => {
    setCreatingBackup(true)

    try {
      const collectionData = await createCollectionBackup(collectionName, config.environment)
      // download collectionData
      const blob = new Blob([JSON.stringify(collectionData, null, 2)], { type: 'application/json' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${config.environment}_${collectionName}_backup.json`
      a.click()
      URL.revokeObjectURL(url)
    } catch (error) {
      console.log("backup error", error)
      setImportError(error)
      alert(error.message)
    }

    setCreatingBackup(false)
  }
  
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={isOpen}
      onHide={onClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <div className='as-form-row align-items-center justify-content-between'>
          <h5 className='modal-title'>{`Import collection [${collectionName}] from environment [${sourceEnvironment}]`}</h5>
          <button
            type='button'
            className='btn btn-icon btn-sm btn-active-light-primary'
            onClick={onClose}
          >
            <i className='bi bi-x-lg'></i>
          </button>
        </div>
      </div>
      <ModalBody>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Import strategy</label>
          <select
            value={importStrategy}
            onChange={(e) => setImportStrategy(e.target.value)}
            className="form-select form-select-solid form-select-lg mt-2"
          >
            <option value="merge">Merge</option>
            <option value="replace">Replace</option>
          </select>
          {importStrategy === 'merge' && (
            <p className="text-muted mt-2">Existing documents with matching IDs will be updated, new documents will be added, existing documents not in the import will remain unchanged.</p>
          )}
          {importStrategy === 'replace' && (
            <p className="text-muted mt-2">Existing documents will be deleted and replaced with the import.</p>
          )}
        </div>
        {/* {importError && (
          <div className="mb-4">
            < variant='error' title={importError.message} />
          </div> */}
        {/* )} */}
        <div className="d-flex gap-2">
          <Button
            onClick={handleCreateBackup}
            disabled={creatingBackup}
            
          >
            {creatingBackup ? 'Creating backup...' : `Backup [${config.environment}] [${collectionName}]`}
            {creatingBackup && 
              <span className="spinner-border spinner-border-sm spinner-border-primary ms-2"></span>
            }
          </Button>
          <Button
          
            onClick={handleImport}
            disabled={importing}
            loading={importing}
          >
            {importing ? 'Importing...' : 'Import'}
            {importing && 
              <span className="spinner-border spinner-border-sm spinner-border-primary ms-2"></span>
            }
          </Button>
        </div>
      </ModalBody>
      <div class="toast" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <img src="..." class="rounded me-2" alt="..." />
        <strong class="me-auto">Bootstrap</strong>
        <small>11 mins ago</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        Hello, world! This is a toast message.
      </div>
    </div>
    </Modal>, modalsRoot)
}

export default ImportCollectionModal
