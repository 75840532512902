/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useContext, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { ProductsContext } from '../../context'
import ReorderableList from '../../components/ReorderableList'

/**
 * voucher: {
 * id: string,
 * title: string,
 * code: string,
 * discount: number,
 * single_use: boolean,
 * use_count: number,
 * availability_start: number, // timestamp
 * availability_end: number, // timestamp
 * all_products: boolean,
 * local_products: string[], // product ids
 * }
 */

const modalsRoot = document.getElementById('root-modals') || document.body

const VoucherDetailModal = ({show, handleClose, voucher, setVoucher, onSubmit, onDelete}) => {

  const { genericProducts, specificProducts, localProducts, domains, durations, resorts } = useContext(ProductsContext)
  const [selectedLanguage, setSelectedLanguage] = useState('fr')
  const [productSearch, setProductSearch] = useState('')
  const [filteredProducts, setFilteredProducts] = useState([])

  useEffect(() => {
    let fp = localProducts.filter(product => !voucher?.products?.includes(product.id))
    if(productSearch.length > 0) {
      fp = fp.filter(product => labelForLocalProduct(product.id).toLowerCase().includes(productSearch.toLowerCase()))
      setFilteredProducts(fp)
    } else {
      setFilteredProducts([])
    }
  }, [productSearch, voucher, localProducts])

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...voucher, ...fieldsToUpdate}
    setVoucher(updatedData)
  }

  const labelForLocalProduct = (id) => {
    const localProduct = localProducts.find(p => p.id === id)
    const specificProduct = specificProducts.find(p => p.id === localProduct?.specific_product)
    const genericProduct = genericProducts.find(p => p.id === specificProduct?.generic_product)
    // const domain = domains.find(d => d.id === genericProduct?.domain)
    // const duration = durations.find(d => d.id === genericProduct?.duration)
    const resort = resorts.find(r => r.id === localProduct?.resort)  
    // const domainLabel = domain?.title[selectedLanguage] || 'No domain'
    // const durationLabel = duration?.title[selectedLanguage] || 'No duration'
    const resortLabel = localProduct?.all_resorts ? 'All resorts' : resort?.name[selectedLanguage] || 'No resort'
    // return `${domainLabel} - ${durationLabel} - ${resortLabel}`
    return `${specificProduct?.name[selectedLanguage]} - ${resortLabel}`
  }

  const voucherMinReduction = 0
  const voucherMaxReduction = 99

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{ voucher?.id ? 'Update Voucher' : 'Create Voucher' }</h2>
        {/* begin::Close */}
        {/* <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div> */}
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          <form noValidate id='kt_modal_create_app_form'>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold'>
                <span className='required'>Title</span>
              </label>
              <span className='text-muted mb-2'>(not visible to users)</span>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='voucher-title'
                placeholder=''
                value={voucher?.title || ''}
                onChange={(e) =>
                  updateData({
                    title: e.target.value,
                  })
                }
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Code</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='voucher-code'
                placeholder=''
                value={voucher?.code || ''}
                onChange={(e) =>
                  updateData({
                    code: e.target.value,
                  })
                }
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Discount <span className='text-muted fs-8'>{'(%)'}</span></span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid pe-5'
                name='voucher-discount'
                placeholder='10%'
                min={voucherMinReduction}
                max={voucherMaxReduction}
                value={voucher?.discount !== undefined ? voucher.discount.toFixed(0) : ''}
                onChange={(e) => {
                  let value = parseInt(e.target.value)
                  if (value < voucherMinReduction) {
                    value = voucherMinReduction
                  } else if (value > voucherMaxReduction) {
                    value = voucherMaxReduction
                  }
                  updateData({
                    discount: value,
                  })
                }}
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Single Use</span>
              </label>
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='voucher-single-use'
                  checked={voucher?.single_use}
                  onChange={(e) =>
                    updateData({
                      single_use: e.target.checked,
                    })
                  }
                />
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Availability Start
                  { voucher?.single_use && <span className='text-muted fs-8'>{' (not applicable for single use voucher)'}</span> }
                </span>
              </label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                name='voucher-availability-start'
                placeholder=''
                disabled={voucher?.single_use}
                value={voucher?.availability_start ? new Date(voucher.availability_start).toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  const date = new Date(e.target.value)
                  date.setHours(0, 0, 0, 0)

                  updateData({
                    availability_start: date.getTime(),
                  })
                }}
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Availability End
                  { voucher?.single_use && <span className='text-muted fs-8'>{' (not applicable for single use voucher)'}</span> }
                </span>
              </label>
              <input
                type='date'
                className='form-control form-control-lg form-control-solid'
                name='voucher-availability-end'
                placeholder=''
                disabled={voucher?.single_use}
                value={voucher?.availability_end ? new Date(voucher.availability_end).toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  const date = new Date(e.target.value)
                  date.setHours(23, 59, 59, 999)
                  updateData({
                    availability_end: date.getTime(),
                  })
                }}
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Available for all products</span>
              </label>
              <div className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='voucher-all-products'
                  checked={voucher?.all_products}
                  onChange={(e) =>
                    updateData({
                      all_products: e.target.checked,
                      products: [],
                    })
                  }
                />
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Local products
                  { voucher?.all_products && <span className='text-muted fs-8'>{' (not applicable for "all products" voucher)'}</span> }
                </span>
              </label>
              <input
                disabled={voucher?.all_products}
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='voucher-products'
                placeholder='Type to search products'
                value={productSearch}
                onChange={(e) => {
                  setProductSearch(e.target.value)
                }}
              />
              <div className='fv-row mb-10'>
                <div className='d-flex flex-wrap'>
                  { filteredProducts.map((product) =>
                    <div key={`product_${product.id}`} className='btn btn-sm btn-light btn-active-primary m-1' onClick={() => {
                      updateData({
                        products: [...(voucher?.products || []), product.id]
                      })
                      // setProductSearch('')
                    }}>
                      { labelForLocalProduct(product.id) }
                    </div>
                  )}
                </div>

                <div className='d-flex flex-wrap'>
                  <ReorderableList
                    disabled
                    className='mt-2'
                    onReorder={(orderedItemIds) => updateData({
                      products: [...orderedItemIds]
                    })}
                    items={(voucher?.products || []).map((productId) => {
                      return (
                        <div key={productId} className='bg-secondary text-inverse-secondary px-3 py-2 card-rounded d-flex flex-row align-center justify-content-between'>
                          { labelForLocalProduct(productId) }         
                          <div className='btn btn-sm btn-icon btn-active-color-secondary text-inverse-secondary ms-2 flex-1' onClick={() => {
                            updateData({
                              products: (voucher?.products || []).filter(id => id !== productId)
                            })
                          }}>
                            <i className='fas fa-times'></i>
                          </div>
                        </div>
                      )
                    })}
                  />
                </div>
              </div>
            </div>

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
              <div className='me-2'>
                { voucher?.id &&
                  <button
                    type='button'
                    className='btn btn-lg btn-danger'
                    data-kt-stepper-action='next'
                    onClick={onDelete}
                  >
                    { 'Delete' }
                  </button>
                }
              </div>                
              <div className='d-flex gap-3'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={onSubmit}
                >
                  { voucher?.id ? 'Update' : 'Create' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default VoucherDetailModal
