import { SET_ARTICLE_SECTIONS, SET_ARTICLES } from '../types';

const articlesReducer = (state, action) => {
  switch(action.type) {
    case SET_ARTICLE_SECTIONS:
      return{
        ...state,
        articleSections: action.payload,
        articleSectionsLoaded: true
      }
    case SET_ARTICLES:
      return{
        ...state,
        articles: action.payload,
        articlesLoaded: true
      }
    default:
      return state;
  }
}

export default articlesReducer