/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useContext, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { ProductsContext } from '../../context'
import { Search } from '../../../_metronic/partials'
import ImageInput from '../../components/ImageInput'
import FileInput from '../../components/FileInput'
import { uploadFile } from '../../services/firebase'
// import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css';
import config from '../../../config.json'
import DPicker, { dateToString } from '../../components/DatePicker'
import moment from 'moment'

const modalsRoot = document.getElementById('root-modals') || document.body

const LocalProductDetailModal = ({show, handleClose, product, setProduct, onSubmit, onDelete}) => {

  const { specificProducts, genericProducts, resorts, durations, domains, resellers } = useContext(ProductsContext)
  const [selectedLanguage, setSelectedLanguage] = useState('fr')

  const domainForEnvironment = {
    "development": "assurgliss-development.web.app",
    "staging": "assurgliss-staging.web.app",
    "production": "assuranceski.com"
  }

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...product, ...fieldsToUpdate}
    setProduct(updatedData)
  }

  const handleSubmit = async () => {
    const data = {...product}
    onSubmit(data)
  }

  const detailsForProduct = () => {
    if(!product?.specific_product) return ""
    const specificProduct = specificProducts.find(p => p.id === product.specific_product)
    if(!specificProduct) return ""
    const genericProduct = genericProducts.find(p => p.id === specificProduct.generic_product)
    if(!genericProduct) return ""
    const duration = durations.find(p => p.id === genericProduct.duration)
    const domain = domains.find(p => p.id === genericProduct.domain)

    return `Generic: ${genericProduct.name[selectedLanguage]} - ${domain?.title?.[selectedLanguage] || '(no domain)'} - ${duration?.title?.[selectedLanguage] || '(no duration)'}`
  }

  const handleImportDates = () => {
    const resort = resorts.find(r => r.id === product.resort)
    if(!resort) {
      alert('No resort selected')
      return
    }
    const updates = {}
    if(resort.sale_start_date) updates.sale_start_date = resort.sale_start_date
    if(resort.sale_end_date) updates.sale_end_date = resort.sale_end_date
    if(Object.keys(updates).length === 0) {
      alert('No dates found for this resort')
      return
    }
    updateData(updates)
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <div className='as-form-row align-items-center justify-content-between'>
          <h2>{ product?.id ? 'Update Local Product' : 'Create Local Product' }</h2>
          <div>
            <label className='mb-2'>
              <span>Language</span>
            </label>
            <div className='nav-group nav-group-outline nav-group-slim'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='fr'
                  checked={selectedLanguage === 'fr'}
                  onChange={(e) => setSelectedLanguage('fr')}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  FR
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  EN
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  ES
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  IT
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='modal-body'>
        <div className='flex-row-fluid px-lg-5'>
            <div className='fv-row mb-4'>
              <label className='mb-2'>
                <span>Created: <b>{moment(product?.meta?.created || 0).format('DD/MM/YYYY HH:mm')}</b></span><br />
                <span>Updated: <b>{moment(product?.meta?.updated || 0).format('DD/MM/YYYY HH:mm')}</b></span>
                {config.environment === 'development' && <><br /><span>ID: <b>{product?.id}</b></span></>}
              </label>
            </div>

            <div className='fv-row mb-4'>
              <label className='mb-2'>
                <span className='required'>Specific product</span>
              </label>
              <select className='form-select form-select-solid form-select-lg' name="specific_product" id="specific_product" value={product?.specific_product || ""} onChange={e => updateData({
                  specific_product: e.target.value
                }
              )}>
                <option disabled value="">{"Select Specific Product"}</option>
                { specificProducts.map((p, pi) =>
                  <option key={`specific_product_option_${pi}`} value={p.id}>{p.name.fr}</option>
                )}
              </select>
            </div>
            <div className='mb-6'>
              <span className='text-muted'>{ detailsForProduct() }</span>
            </div>

            <div className='fv-row'>
            </div>
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Resort</span>
              </label>
              <div className='mt-2 mb-4 fw-semibold form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  name='all-resorts'
                  checked={product?.all_resorts}
                  onChange={e => updateData({ all_resorts: e.target.checked })}
                />
                <label className='form-check-label'>{ "All resorts (Referral product)" }</label>
              </div>
              { !product?.all_resorts &&
                <select disabled={product?.all_resorts} className='form-select form-select-solid form-select-lg' name="resort" id="resort" value={product?.all_resorts ? '' : product?.resort || ""} onChange={e => updateData({
                    resort: e.target.value
                  }
                )}>
                  <option disabled value="">{"Select resort"}</option>
                  { resorts.map((r, ri) =>
                    <option key={`resort_option_${ri}`} value={r.id}>{r.name.fr}</option>
                  )}
                </select>
              }
              {product?.resort_name && <label className="mt-2"><span className='text-muted'>{ `Col h: ${product.resort_name}` }</span></label>}
            </div>

            { product?.all_resorts &&
              <div className='fv-row mb-6'>
                <label className='mb-2'>
                  <span>Referral ID</span>
                </label>

            {/* <div className='mb-6'> */}
            {/* </div> */}
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='product-referral-id'
                  placeholder=''
                  value={product?.referral_id || ''}
                  onChange={(e) =>
                    updateData({
                      referral_id: e.target.value
                    })
                  }
                />
                <div className='mt-2'>
                  <span className='text-muted'>For direct purchase URL</span>
                </div>
              </div>
            }

            <div className='d-flex flex-row gap-3 align-items-end mb-6'>
              <div className='fv-row'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Sale start date</span>
                </label>
                <DPicker selected={product?.sale_start_date} onChange={(date) => {
                  updateData({
                    sale_start_date: date,
                  })
                }} />
              </div>
              <div className='fv-row'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Sale end date</span>
                </label>
                <DPicker  selected={product?.sale_end_date} onChange={(date) => {
                  updateData({
                    sale_end_date: date,
                  })
                }} />
              </div>
              <div>
                <button className='btn btn-secondary btn-sm' onClick={handleImportDates}>Import from resort (legacy)</button>
              </div>
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Public Price</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-price'
                placeholder=''
                value={product?.price || 0}
                onChange={(e) =>
                  updateData({
                    price: parseFloat(e.target.value)
                  })
                }
              />
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Price without tax</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-price-ht'
                placeholder=''
                value={product?.price_ht || 0}
                onChange={(e) =>
                  updateData({
                    price_ht: parseFloat(e.target.value)
                  })
                }
              />
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Insurance tax</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-insurance-tax'
                placeholder=''
                value={product?.insurance_tax || 0}
                onChange={(e) =>
                  updateData({
                    insurance_tax: parseFloat(e.target.value)
                  })
                }
              />
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Operator share</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-operator-share'
                placeholder=''
                value={product?.share_operator || 0}
                onChange={(e) =>
                  updateData({
                    share_operator: parseFloat(e.target.value)
                  })
                }
              />
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Clubs share</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-clubs-share'
                placeholder=''
                value={product?.share_club || 0}
                onChange={(e) =>
                  updateData({
                    share_club: parseFloat(e.target.value)
                  })
                }
              />
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Others share</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-others-share'
                placeholder=''
                value={product?.share_other || 0}
                onChange={(e) =>
                  updateData({
                    share_other: parseFloat(e.target.value)
                  })
                }
              />
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Publisher share</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-publisher-share'
                placeholder=''
                value={product?.share_webapp || 0}
                onChange={(e) =>
                  updateData({
                    share_webapp: parseFloat(e.target.value)
                  })
                }
              />
            </div>

            <div className='fv-row mb-4'>
              <label className='mb-2'>
                <span>Network</span>
              </label>
              <select className='form-select form-select-solid form-select-lg' name="reseller" id="reseller" value={product?.reseller || ""} onChange={e => updateData({
                  reseller: e.target.value
                }
              )}>
                <option disabled value="">{"Select Network"}</option>
                { resellers.map((r, ri) =>
                  <option key={`reseller_option_${ri}`} value={r.id}>{r.company_name}</option>
                )}
              </select>
              {product?.company_name && <label className="mt-2"><span className='text-muted'>{ `Col c: ${product.company_name}` }</span></label>}
            </div>

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3 mt-10'>
              <div className='me-2'>
              { product?.id &&
                <button
                  type='button'
                  className='btn btn-lg btn-danger'
                  data-kt-stepper-action='next'
                  onClick={onDelete}
                >
                  { 'Delete' }
                </button> }
              </div>
              <div className='d-flex gap-3'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={handleSubmit}
                >
                  { product?.id ? 'Update' : 'Create' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default LocalProductDetailModal
