export const ARTICLE_SECTIONS = 'article_sections'
export const ARTICLES = 'articles'
// export const PRODUCTS = 'products'
export const GENERIC_PRODUCTS = 'products_generic'
export const SPECIFIC_PRODUCTS = 'products_specific'
export const LOCAL_PRODUCTS = 'products_local'
export const RESORTS = 'resorts'
export const DOMAINS = 'domains'
export const DURATIONS = 'durations'
export const RESELLERS = 'resellers'
export const COUNTRIES = 'countries'
export const REGIONS = 'regions'
export const USERS = 'users'
export const PURCHASES = 'purchases'
export const NEWS = 'news'
export const INFO_BLOCKS = 'info_blocks'
export const CONTACT_FORMS = 'contact_forms'
export const PRODUCT_SECTIONS = 'product_sections'
export const FAQS = 'faqs'
export const PAYMENT_REQUESTS = 'payment_requests'
export const RESORT_PARTNERS = 'resort_partners'
export const GUARANTEES = 'product_guarantees'
export const EXCLUSIONS = 'product_exclusions'
export const VOUCHERS = 'vouchers'