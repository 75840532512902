import React, {useContext, useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'
import { EditorState } from "draft-js"
import { createEditorStateFromRaw, serialiseEditorStateToRaw } from "draftail"

import { ArticlesContext } from '../../context'
import Editor, { customDecorators, fromHTML, toHTML } from '../../components/Editor'
import { Button } from 'react-bootstrap'



const ContactPage = () => {

  const [content, setContent] = useState(null)

  const stepperRef = useRef(null)
  const [selectedLanguage, setSelectedLanguage] = useState('fr')

  const [editorStates, setEditorStates] = useState({
    header: {
      en: EditorState.createEmpty(),
      fr: EditorState.createEmpty(),
      it: EditorState.createEmpty(),
      es: EditorState.createEmpty()
    }, footer: {
      en: EditorState.createEmpty(),
      fr: EditorState.createEmpty(),
      it: EditorState.createEmpty(),
      es: EditorState.createEmpty()
    }
  })

  useEffect(() => {
    if(!content) {
      return
    }
    setEditorStates({
      header: {
        en: createEditorStateFromRaw(fromHTML(content.header?.en || ''), customDecorators),
        fr: createEditorStateFromRaw(fromHTML(content.header?.fr || ''), customDecorators),
        it: createEditorStateFromRaw(fromHTML(content.header?.it || ''), customDecorators),
        es: createEditorStateFromRaw(fromHTML(content.header?.es || ''), customDecorators)
      }, 
      footer: {
        en: createEditorStateFromRaw(fromHTML(content.footer?.en || ''), customDecorators),
        fr: createEditorStateFromRaw(fromHTML(content.footer?.fr || ''), customDecorators),
        it: createEditorStateFromRaw(fromHTML(content.footer?.it || ''), customDecorators),
        es: createEditorStateFromRaw(fromHTML(content.footer?.es || ''), customDecorators)
      }
    })
  }, [content])


  const handleEditorChange = (section) => (content) => {
    setEditorStates({
      ...editorStates,
      [section]: {
        ...(editorStates[section] || {}),
        [selectedLanguage]: content
      }
    })
  }

  const onSave = () => {
    const cont = {...content}
    cont.header = {
      en: toHTML(serialiseEditorStateToRaw(editorStates.header.en)),
      fr: toHTML(serialiseEditorStateToRaw(editorStates.header.fr)),
      it: toHTML(serialiseEditorStateToRaw(editorStates.header.it)),
      es: toHTML(serialiseEditorStateToRaw(editorStates.header.es)),
    }
    cont.footer = {
      en: toHTML(serialiseEditorStateToRaw(editorStates.footer.en)),
      fr: toHTML(serialiseEditorStateToRaw(editorStates.footer.fr)),
      it: toHTML(serialiseEditorStateToRaw(editorStates.footer.it)),
      es: toHTML(serialiseEditorStateToRaw(editorStates.footer.es)),
    }
    // TODO save contact article
    onSubmit(cont)
  }
  
  const {
    contentArticles,
    articlesLoaded,
    fetchArticles,
    updateArticle,
  } = useContext(ArticlesContext)

  useEffect(() => {
    if(!articlesLoaded) {
      return
    }
    const cont = contentArticles.find(a => a.id === 'commercial-contact')
    if(cont) {
      setContent(cont)
    } else {
      setContent({
        id: 'commercial-contact',
        header: {
          en: '',
          fr: '',
          it: '',
          es: ''
        },
        footer: {
          en: '',
          fr: '',
          it: '',
          es: ''
        }
      })
    }
  }, [contentArticles, articlesLoaded])

  useEffect(() => {
    if(!articlesLoaded) {
      fetchArticles()
    }
  }, [articlesLoaded, fetchArticles])

  const onSubmit = async (content) => {
    let response
    const data = {...content}
    // if(!data.id) {
    //   response = await createArticle(data)
    // } else {
    await updateArticle("commercial-contact", data)
  }
  
  const handleCopyContent = (section) => () => {
    setEditorStates({
      ...editorStates,
      [section]: {
        ...(editorStates[section] || {}),
        [selectedLanguage]: editorStates[section]["fr"]
      }
    })
  }
  
  const handleExportContent = (section) => () => {
    const html = toHTML(serialiseEditorStateToRaw(editorStates[section][selectedLanguage]))
    const a = document.createElement('a')
    a.download = `page-commercial-contact-${section}-${selectedLanguage}.html`
    a.href = 'data:text/html;charset=utf-8,' + encodeURIComponent(html)
    a.click()
  }

  const handleImportContent = (section) => () => {
    const input = document.createElement('input')
    input.type = "file"
    input.accept = "text/html"
    input.onchange = e => {
      const file = e.target.files[0];
      if(!file) return
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = readerEvent => {
        const content = readerEvent.target.result
        setEditorStates({
          ...editorStates,
          [section]: {
            ...editorStates[section],
            [selectedLanguage]: createEditorStateFromRaw(fromHTML(content), customDecorators)
          }
        })
      }
    }
    input.click()
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Commercial contact page content'}        
        loading={!articlesLoaded} />
      {/* begin::Row */}
      <div className='row g-6 g-xl-9 mt-1'>

      <div className="">
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >


          {/*begin::Content */}
          <div className='flex-row-fluid'>
            <div className='mb-5'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Language</span>
              </label>
              <div className='nav-group nav-group-outline nav-group-slim'>
                <label>
                  <input
                    type='radio'
                    className='btn-check'
                    name='type'
                    value='fr'
                    checked={selectedLanguage === 'fr'}
                    onChange={(e) => setSelectedLanguage('fr')}
                  />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                    FR
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    EN
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    ES
                  </span>
                </label>

                <label>
                  <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                  <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                    IT
                  </span>
                </label>
              </div>
            </div>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>{ `Header (above contact form)` }</span>
                  { selectedLanguage !== "fr" && <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleCopyContent("header")} size="sm" className="ml-5">{'Copy from FR'}</Button></> }
                  <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleExportContent("header")} size="sm" className="ml-5">{'Export to HTML'}</Button></>
                  <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleImportContent("header")} size="sm" className="ml-5">{'Import from HTML'}</Button></>
                </label>
                { content && <div>
                  <Editor
                    editorState={editorStates.header[selectedLanguage]}
                    onChange={handleEditorChange("header")}
                    entities={['link', 'image', 'button', 'attachment']} />
                  </div>
                  }
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>{ `Footer (below contact form)` }</span>
                  { selectedLanguage !== "fr" && <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleCopyContent("footer")} size="sm" className="ml-5">{'Copy from FR'}</Button></> }
                  <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleExportContent("footer")} size="sm" className="ml-5">{'Export to HTML'}</Button></>
                  <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleImportContent("footer")} size="sm" className="ml-5">{'Import from HTML'}</Button></>
                </label>
              </div>
              { content && <div>
                <Editor
                    editorState={editorStates.footer[selectedLanguage]}
                    onChange={handleEditorChange("footer")}
                    entities={['link', 'image', 'button', 'attachment']} />
                  </div>
                }
              {/*begin::Actions */}
              <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3 mt-3'>
                <div className='me-2'>
                </div>                
                <div className='d-flex gap-3'>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={onSave}
                  >
                    { 'Update' }
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
      </div>
    </>
  )
}

const ArticlesWrapper = () => {
  return (
    <>
      <ContactPage />
    </>
  )
}

export default ArticlesWrapper
