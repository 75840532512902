import React, { useEffect, useRef, useState } from "react"

const ReorderableList = ({ items, onReorder, className, disabled }) => {

  const [list, setList] = useState(items)
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [draggedOverIndex, setDraggedOverIndex] = useState(null)
  const draggedIndexRef = useRef(null)
  const draggedOverIndexRef = useRef(null)

  useEffect(() => {
    setList(items)
  }, [items])

  const onDragStart = (e) => {
    if(disabled) return
    const dIndex = parseInt(e.target.dataset.index)
    draggedIndexRef.current = dIndex
    setDraggedIndex(dIndex)
  }

  const onDragOver = (e) => {
    e.preventDefault()
    if(disabled) return
    const doIndex = parseInt(e.currentTarget.dataset.index)
    draggedOverIndexRef.current = doIndex
    setDraggedOverIndex(doIndex)
  }

  const onDragEnd = (e) => {    
    if(disabled) return
    const dIndex = draggedIndexRef.current
    const doIndex = draggedOverIndexRef.current
    setDraggedIndex(null)
    setDraggedOverIndex(null)
    if (dIndex === doIndex) return
    const newList = [...list]
    const [removed] = newList.splice(dIndex, 1)
    newList.splice(doIndex, 0, removed)
    setList(newList)
    onReorder(newList.map((item, i) => item.key))
  }


  return (
    <div className={`reorderable-list ${className || ''}`}>
      {list.map((item, i) => (
        <div
          key={`reorderable-list-item-${i}`}
          className={`reorderable-list-item ${disabled ? 'disabled' : ''} ${draggedIndex === i ? 'dragged' : ''} ${draggedOverIndex === i && draggedIndex !== i ? 'dragged-over' : ''} ${draggedIndex < i ? 'move-down' : draggedIndex > i ? 'move-up' : ''}`}
          draggable={!disabled}
          onDragStart={onDragStart}
          onDragOver={onDragOver}
          onDragEnd={onDragEnd}
          data-index={i}
          >
          {item}
        </div>
      ))}
    </div>
  )
}

export default ReorderableList

