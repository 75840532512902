/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts from 'apexcharts'
import { getCSS, getCSSVariableValue } from '../../../_metronic/assets/ts/_utils'

const ColumnsChart = ({ className, title, subtitle, data, filters, defaultFilter, yFormatter = (val) => val, chartType = 'bar', baseColor = '#009ef7', lightColor = '#f1faff' }) => {
  const chartRef = useRef(null)

  const [activeFilter, setActiveFilter] = useState(defaultFilter || Object.keys(data)[0])

  useEffect(() => {
    const refreshChart = () => {
      if (!chartRef.current) {
        return
      }
  
      const height = parseInt(getCSS(chartRef.current, 'height'))
  
      const chart = new ApexCharts(chartRef.current, getChartOptions(data[activeFilter], chartType, { base: baseColor, light: lightColor }, yFormatter, height))
      if (chart) {
        chart.render()
      }
  
      return chart
    }

    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, data, activeFilter, yFormatter])
  

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{ title }</span>

          <span className='text-muted fw-semibold fs-7'>{ subtitle }</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          { filters.map((filter, fi) =>
            <a
              key={`filter_${fi}`}
              className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${activeFilter === filter.value ? 'active' : ''}`}
              id='kt_charts_widget_2_year_btn'
              onClick={() => setActiveFilter(filter.value)}
            >
              { filter.label}
            </a>
          )}
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_2_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

function getChartOptions(data, chartType, colors, yFormatter, height) {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')
  // const baseColor = getCSSVariableValue('--kt-primary')
  // const baseColor = "#f00"
  // const secondaryColor = getCSSVariableValue('--kt-gray-300')

  return {
    series: data.series,
    chart: {
      fontFamily: 'inherit',
      type: chartType,
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      // type: 'solid',
      opacity: 1,
      colors: [colors.base, "#f00"],
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      colors: [colors.base],
    },
    xaxis: {
      categories: data.xAxisLabels,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: yFormatter
      },
    },
    colors: [colors.base, colors.light],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

export default ColumnsChart
