import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {ArticlesState, ContactState, ContentState, CustomersState, ProductsState} from './context'
import {AuthInit} from './modules/auth'
import NotificationsState from './context/notifications/notificationsState'

const App = () => {
  return (
    <NotificationsState>
      <ArticlesState>
        <ProductsState>
          <CustomersState>
            <ContentState>
              <ContactState>
                <Suspense fallback={<LayoutSplashScreen />}>
                  <I18nProvider>
                    <LayoutProvider>
                      <AuthInit>
                        <Outlet />
                        <MasterInit />
                      </AuthInit>
                    </LayoutProvider>
                  </I18nProvider>
                </Suspense>
              </ContactState>
            </ContentState>
          </CustomersState>
        </ProductsState>
      </ArticlesState>
    </NotificationsState>
  )
}

export {App}
