import {useContext, useEffect, useState} from 'react'
import moment from 'moment'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ProductsContext } from '../../context'
import ResellerDetailModal from './ResellerDetailModal'
import ImportCollectionModal from '../../components/ImportCollectionModal'
import config from '../../../config.json'

const ResellersPage = () => {
  
  const { resellers, resellersLoaded, fetchResellers, createReseller, updateReseller, deleteReseller, resortsLoaded, fetchResorts, resorts } = useContext(ProductsContext)

  const [currentReseller, setCurrentReseller] = useState(null)
  const [sortBy, setSortBy] = useState('company_name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [filteredResellers, setFilteredResellers] = useState([])
  const [importCollectionModalOpen, setImportCollectionModalOpen] = useState(false)

  const onSortClick = (property) => {
    if(sortBy !== property) {
      setSortBy(property)
      setSortOrder('asc')
    } else {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    }
  }

  useEffect(() => {
    if(!resellersLoaded) {
      return
    }
    let filtered = [...resellers]
    if(sortBy === 'company_name') {
      filtered = filtered.sort((a, b) => {
        if(a.company_name?.toLowerCase() < b.company_name?.toLowerCase()) {
          return sortOrder === 'asc' ? -1 : 1
        }
        if(a.company_name?.toLowerCase() > b.company_name?.toLowerCase()) {
          return sortOrder === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    if(sortBy === 'updated') {
      filtered = filtered.sort((a, b) => {
        console.log(`Sort ${a.meta?.updated} ${b.meta?.updated}`)
        if(a.meta?.updated < b.meta?.updated) {
          return sortOrder === 'asc' ? -1 : 1
        }
        if(a.meta?.updated > b.meta?.updated) {
          return sortOrder === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    if(sortBy === 'resorts') {
      filtered = filtered.sort((a, b) => {
        if((a.resorts || []).length < (b.resorts || []).length) {
          return sortOrder === 'asc' ? -1 : 1
        }
        if((a.resorts || []).length > (b.resorts || []).length) {
          return sortOrder === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    setFilteredResellers(filtered)
  }, [resellersLoaded, resellers, sortBy, sortOrder])


  useEffect(() => {
    if(!resellersLoaded) {
      fetchResellers()
    }
  }, [resellersLoaded, fetchResellers])

  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])


  const onCreateClick = () => {
    setCurrentReseller({
      id: '',
      name: '',
      country: ''
    })
  }

  const onEditClick = (reseller) => {
    setCurrentReseller(reseller)
  }

  const onDetailSubmit = async () => {
    let response
    const data = {...currentReseller}
    if(!data.id) {
      response = await createReseller(data)
    } else {
      response = await updateReseller(data.id, data)
    }
    setCurrentReseller(null)
  }

  const onDetailDelete = async () => {
    if(!confirm(`Are you sure you want to delete this reseller? (${currentReseller?.name || currentReseller.id})`)) return
    let response = await deleteReseller(currentReseller.id)
    setCurrentReseller(null)
  }

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  const limitCharacters = (text, limit) => {
    if(!text) return ''
    if(text.length > limit) {
      return text.substring(0, limit) + '...'
    }
    return text
  }

  const promptImportCollection = () => {
    setImportCollectionModalOpen(true)
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Networks'}
        primaryButton={resellersLoaded && { onClick: onCreateClick, title: 'Add' }}
        loading={!resellersLoaded}
        filterElements={[(
          config.environment !== 'staging' && <button className='btn btn-sm fw-bold btn-primary' onClick={promptImportCollection}>
            <KTSVG path='/media/icons/duotune/arrows/arr037.svg' className='svg-icon-3' />
            Import from staging
          </button>
        )]}
      />

      {/* List of resellers - sortable table with properties: company_name, meta?.updated */}
      <div className='row g-6 g-xl-9 mt-1'>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold text-muted'>
              <th className='w-15px'>
              </th>
              <th onClick={() => onSortClick('company_name')} className='min-w-100px'>{`Company name${sortBy === 'company_name' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th onClick={() => onSortClick('resorts')} className='min-w-100px'>{`Resorts${sortBy === 'resorts' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              <th onClick={() => onSortClick('updated')} className='min-w-80px text-center'>{`Updated${sortBy === 'updated' ? sortOrder === 'desc' ? '↑' : '↓' : ''}`}</th>
              {/* <th className='min-w-100px text-end'></th> */}
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>
          { resellersLoaded && filteredResellers.map((reseller) => 
            <tr key={`reseller_${reseller.id}`}>
              <td>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className='text-dark fw-bold text-hover-primary fs-6 cursor-pointer' onClick={() => onEditClick(reseller)}>
                      { reseller.company_name }
                    </div>
                    {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      { `${itemWithId(resorts || [], purchase.resort)?.name?.fr || ''}, ${itemWithId(domains || [], purchase.domain)?.title?.fr || ''}, ${itemWithId(durations || [], purchase.duration)?.title?.fr || ''}` }
                    </span> */}
                  </div>
                </div>
              </td>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='d-flex justify-content-start flex-column'>
                    <div className='text-dark fs-7'>
                      { (reseller.resorts || []).length }
                    </div>
                    <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      { limitCharacters((reseller.resorts || []).map(resortId => itemWithId(resorts || [], resortId)?.name?.fr || '').join(', '), 40)}
                    </span>
                  </div>
                </div>
              </td>
              {/* <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                   { `${(purchase.amount / 100).toFixed(2)}€` }
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                   { `${purchase.buyer.firstname} ${purchase.buyer.lastname}` }
                </span>
                <span className='text-muted fw-semibold text-muted d-block fs-9'>
                  { purchase.user }
                </span>
              </td>
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                   { purchase.paid_at ? moment(purchase.paid_at).format('DD/MM/YYYY HH:mm') : purchase.meta?.updated ? moment(purchase.meta?.updated).format('DD/MM/YYYY HH:mm') : 'N/A' }
                </span>
              </td> */}
              <td>
                <span className='text-muted fw-semibold text-muted d-block fs-7 text-center'>
                    { reseller.meta?.updated ? moment(reseller.meta?.updated).format('DD/MM/YYYY HH:mm') : 'N/A' }
                </span>
              </td>
              {/* <td>
                <div className='d-flex justify-content-end flex-shrink-0'>
                  <button
                  onClick={() => onEditClick(reseller)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    Detail
                  </button>
                </div>
              </td> */}
            </tr>
          )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>


      <ResellerDetailModal
        show={Boolean(currentReseller)}
        handleClose={() => setCurrentReseller(null)}
        reseller={currentReseller}
        setReseller={setCurrentReseller}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
      <ImportCollectionModal
        isOpen={importCollectionModalOpen}
        onClose={() => setImportCollectionModalOpen(false)}
        collectionName={"resellers"}
        sourceEnvironment={"staging"}
        onImportComplete={async () => {
          // reload collection
          await fetchResellers()
        }} />
    </>
  )
}

const ResellersWrapper = () => {
  return (
    <>
      <ResellersPage />
    </>
  )
}

export default ResellersWrapper
