/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useContext, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { ProductsContext } from '../../context'
import { Search } from '../../../_metronic/partials'
import ImageInput from '../../components/ImageInput'
import FileInput from '../../components/FileInput'
import { uploadFile } from '../../services/firebase'
// import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css';
import config from '../../../config.json'
import { dateToString } from '../../components/DatePicker'
import moment from 'moment'
import ReorderableList from '../../components/ReorderableList'

const modalsRoot = document.getElementById('root-modals') || document.body

const GenericProductDetailModal = ({show, handleClose, product, setProduct, onSubmit, onDelete}) => {

  const { domains, domainsLoaded, durations, durationsLoaded, guarantees, exclusions } = useContext(ProductsContext)
  const [selectedLanguage, setSelectedLanguage] = useState('fr')
  const [newImage, setNewImage] = useState(null)
  const [newCoverImage, setNewCoverImage] = useState(null)
  const [brochure, setBrochure] = useState(null)
  const [cg, setCg] = useState(null)
  const [ipid, setIpid] = useState(null)

  const [guaranteesSearch, setGuaranteesSearch] = useState('')
  const [filteredGuarantees, setFilteredGuarantees] = useState([])

  useEffect(() => {
    let fg = guarantees.filter(guarantee => !product?.guarantees?.includes(guarantee.id))
    if(guaranteesSearch.length > 0) {
      fg = fg.filter(guarantee => guarantee.title.fr.toLowerCase().includes(guaranteesSearch.toLowerCase()))
      setFilteredGuarantees(fg)
    } else {
      setFilteredGuarantees([])
    }
  }, [guaranteesSearch, product, guarantees])


  const domainForEnvironment = {
    "development": "assurgliss-development.web.app",
    "staging": "assurgliss-staging.web.app",
    "production": "assuranceski.com"
  }

  useEffect(() => {
    if(!product) {
      setNewImage(null)
      setNewCoverImage(null)
      setBrochure(null)
      setCg(null)
      setIpid(null)
      return
    }
    if(!domainsLoaded || !durationsLoaded) {
      return 
    }
  }, [product,, domainsLoaded, durationsLoaded])

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...product, ...fieldsToUpdate}
    setProduct(updatedData)
  }

  const handleSubmit = async () => {
    const data = {...product}
    if(!data.domain || !data.duration) {
      alert('Please select an activity and a duration')
      return
    }
    if(newImage?.base64) {
      const uploadResponse = await uploadFile({ file: { name: newImage.name, type: newImage.type, size: newImage.size, base64: newImage.base64.split(',')[1]}})
      if(uploadResponse.url) {
        data.image = uploadResponse.url
      }
    }
    if(newCoverImage?.base64) {
      const uploadResponse = await uploadFile({ file: { name: newCoverImage.name, type: newCoverImage.type, size: newCoverImage.size, base64: newCoverImage.base64.split(',')[1]}})
      if(uploadResponse.url) {
        data.cover_image = uploadResponse.url
      }
    }
    if(brochure?.base64) {
      const uploadResponse = await uploadFile({ file: { name: brochure.name, type: brochure.type, size: brochure.size, base64: brochure.base64.split(',')[1]}, path: 'products/brochures'})
      if(uploadResponse.url) {
        data.brochure = uploadResponse.url
      }
    }
    if(cg?.base64) {
      const uploadResponse = await uploadFile({ file: { name: cg.name, type: cg.type, size: cg.size, base64: cg.base64.split(',')[1]}, path: 'products/cg'})
      if(uploadResponse.url) {
        data.cg = uploadResponse.url
      }
    }
    if(ipid?.base64) {
      const uploadResponse = await uploadFile({ file: { name: ipid.name, type: ipid.type, size: ipid.size, base64: ipid.base64.split(',')[1]}, path: 'products/ipid'})
      if(uploadResponse.url) {
        data.ipid = uploadResponse.url
      }
    }
    onSubmit(data)
  }

  const Collapsible = ({children, collapsedContent, title, collapsedByDefault = false}) => {
    const [collapsed, setCollapsed] = useState(collapsedByDefault)
    return (
      <div className={`collapsible ${collapsed ? 'collapsed' : ''} border-top border-bottom`}>
        <button
          type='button'
          className='icon-button collapse-button'
          onClick={() => setCollapsed(!collapsed)}
        >
          <img src={`/media/icons/duotune/arrows/arr0${collapsed ? '65' : '66'}.svg`} />
        </button>
        { !collapsed ? children : collapsedContent || <div>{title}</div>}

      </div>
    )
  }

  const fileNameFromUrl = (url) => {
    if(!url) return ''
    const parts = url.split('/')
    return decodeURIComponent(parts[parts.length - 1].split('?')[0] || '')
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <div className='as-form-row align-items-center justify-content-between'>
          <h2>{ product?.id ? 'Update Generic Product' : 'Create Generic Product' }</h2>
          <div>
            <label className='mb-2'>
              <span>Language</span>
            </label>
            <div className='nav-group nav-group-outline nav-group-slim'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='fr'
                  checked={selectedLanguage === 'fr'}
                  onChange={(e) => setSelectedLanguage('fr')}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  FR
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  EN
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  ES
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  IT
                </span>
              </label>
            </div>
          </div> 
        </div>
      </div>

      <div className='modal-body'>
        <div className='flex-row-fluid px-lg-5'>
            <div className='fv-row mb-4'>
              <label className='mb-2'>
                <span>Created: <b>{moment(product?.meta?.created || 0).format('DD/MM/YYYY HH:mm')}</b></span><br />
                <span>Updated: <b>{moment(product?.meta?.updated || 0).format('DD/MM/YYYY HH:mm')}</b></span>
              </label>
            </div>
                     
            <div className='fv-row'>
              <label className='mb-2'>
                <span className='required'>Generic Product name</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-title'
                placeholder=''
                value={product?.name[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    name: {
                      ...(product?.name || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div> 
            <div className='fv-row'>
              <label className='mb-2 mt-5'>
                <span>Slug (url)</span>
              </label>
              <input
                disabled={true}
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-slug'
                placeholder=''
                value={`https://${domainForEnvironment[config.environment]}/product/${product?.slug?.[selectedLanguage] || product?.id || ''}`}
              />
            </div>
            <div className='fv-row'>
              <div className='mt-5 fw-semibold mb-2 form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  value=''
                  name='active'
                  checked={product?.active}
                  onChange={e => updateData({ active: e.target.checked })}
                />
                <label className='form-check-label'>{ product?.active ? 'Active' : 'Disabled' }</label>
              </div>
            </div>
                     
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Intro text</span>
              </label>
              <textarea
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-intro'
                placeholder=''
                value={product?.intro?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    intro: {
                      ...(product?.intro || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>
                     
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Description</span>
              </label>
              <textarea
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-description'
                placeholder=''
                value={product?.description?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    description: {
                      ...(product?.description || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Activities</span>
              </label>
              <select className='form-select form-select-solid form-select-lg' name="domain" id="domain" value={product?.domain || ""} onChange={e => updateData({
                    domain: e.target.value}
                  )}>
                <option disabled value="">{"Select activities"}</option>
                { domains.map((d, di) => 
                  <option key={`domain_option_${di}`} value={d.id}>{d.title.fr}</option>
                )}
              </select>
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Duration</span>
              </label>
              <select className='form-select form-select-solid form-select-lg' name="duration" id="duration" value={product?.duration || ""} onChange={e => updateData({
                  duration: e.target.value
                }
              )}>
                <option disabled value="">{"Select duration"}</option>
                { durations.map((d, di) => 
                  <option key={`duration_option_${di}`} value={d.id}>{d.title.fr}</option>
                )}
              </select>
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>CG</span>
              </label>                
              <FileInput onFileSelected={i => setCg(i)} fileUrl={product?.cg} fileName={cg?.name ? cg.name : fileNameFromUrl(product?.cg)} onRemove={() => {
                setCg(null)
                updateData({ cg: "" })
              }} />
            </div>



            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>IPID</span>
              </label>                
              <FileInput onFileSelected={i => setIpid(i)} fileUrl={product?.ipid} fileName={ipid?.name ? ipid.name : fileNameFromUrl(product?.ipid)} onRemove={() => {
                setIpid(null)
                updateData({ ipid: "" })
              }} />
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Image</span>
              </label>                
              <ImageInput onImageSelected={i => setNewImage(i)} imageUrl={newImage?.base64 ? newImage.base64 : product?.image} onRemove={() => {
                setNewImage(null)
                updateData({ image: "" })
              }} />
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Cover image</span>
              </label>                
              <ImageInput onImageSelected={i => setNewCoverImage(i)} imageUrl={newCoverImage?.base64 ? newCoverImage.base64 : product?.cover_image} onRemove={() => {
                setNewCoverImage(null)
                updateData({ cover_image: "" })
              }} />
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Brochure</span>
              </label>                
              <FileInput onFileSelected={i => setBrochure(i)} fileUrl={product?.brochure} fileName={brochure?.name ? brochure.name : fileNameFromUrl(product?.brochure)} onRemove={() => {
                setBrochure(null)
                updateData({ brochure: "" })
              }} />
            </div>

            {/* guarantees MULTISELECT */}

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Guarantees</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-guarantees'
                placeholder='Type to search guarantees'
                value={guaranteesSearch}
                onChange={(e) => {
                  setGuaranteesSearch(e.target.value)
                }}
              />
              <div className='fv-row mb-10'>
                <div className='d-flex flex-wrap'>
                  { filteredGuarantees.map((guarantee, guaranteeIndex) =>
                    <div key={`guarantee_${guarantee.id}`} className='btn btn-sm btn-light btn-active-primary m-1' onClick={() => {
                      updateData({
                        guarantees: [...(product?.guarantees || []), guarantee.id]
                      })
                      setGuaranteesSearch('')
                    }}>
                      { guarantee.title.fr }
                    </div>
                  )}
                </div>

                <div className='d-flex flex-wrap'>
                  <ReorderableList 
                    className='mt-2'
                    onReorder={(orderedItemIds) => updateData({
                      guarantees: [...orderedItemIds]
                    })}
                    items={(product?.guarantees || []).map((guaranteeId) => {
                      const guarantee = guarantees.find(guarantee => guarantee.id === guaranteeId)
                      if(!guarantee) {
                        console.log(`Guarantee ${guaranteeId} not found`)
                        return null
                      }
                      return (
                        <div key={guaranteeId} className='bg-secondary text-inverse-secondary px-3 py-2 card-rounded d-flex flex-row align-center justify-content-between'>
                          { guarantee?.title?.fr || guaranteeId }          
                          <div className='btn btn-sm btn-icon btn-active-color-secondary text-inverse-secondary ms-2 flex-1' onClick={() => {
                            updateData({
                              guarantees: (product?.guarantees || []).filter(id => id !== guaranteeId)
                            })
                          }}>
                            <i className='fas fa-times'></i>
                          </div>
                        </div>
                      )
                    })}
                  />
                </div>
              </div>
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Exclusions</span>
              </label>
              <select className='form-select form-select-solid form-select-lg' name="duration" id="duration" value={product?.exclusions || ""} onChange={e => updateData({
                  exclusions: e.target.value
                }
              )}>
                <option disabled value="">{"Select exclusions"}</option>
                { exclusions.map((e, ei) => 
                  <option key={`exclusions_option_${ei}`} value={e.id}>{e.title.fr}</option>
                )}
              </select>
            </div>

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3 mt-10'>
              <div className='me-2'>
                { product?.id &&
                <button
                  type='button'
                  className='btn btn-lg btn-danger'
                  data-kt-stepper-action='next'
                  onClick={onDelete}
                >
                  { 'Delete' }
                </button> }                
              </div>                
              <div className='d-flex gap-3'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={handleSubmit}
                >
                  { product?.id ? 'Update' : 'Create' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default GenericProductDetailModal
