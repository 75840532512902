import {useContext, useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'

import { ProductsContext } from '../../context'
import GuaranteeDetailModal from './GuaranteesDetailModal'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import { itemWithId } from '../../utils'
import ImportCollectionModal from '../../components/ImportCollectionModal'
import config from '../../../config.json'

const GuaranteesPage = () => {
  
  const { guarantees, guaranteesLoaded, fetchGuarantees, createGuarantee, updateGuarantee, deleteGuarantee, genericProducts, genericProductsLoaded, fetchProducts } = useContext(ProductsContext)

  const [currentGuarantee, setCurrentGuarantee] = useState(null)
  const [filteredGuarantees, setFilteredGuarantees] = useState([])
  const [importCollectionModalOpen, setImportCollectionModalOpen] = useState(false)

  useEffect(() => {
    const convertToTableItems = (guarantee) => {
      return {
        ...guarantee,
        // title_label: guarantee.title?.fr || 'No title',
        products: genericProducts?.filter((product) => product.guarantees?.includes(guarantee?.id)).map((product) => product.name?.fr).join(', ')
      }
    }
    setFilteredGuarantees(guarantees.map(convertToTableItems))
  }, [guarantees])

  useEffect(() => {
    if(!guaranteesLoaded) {
      fetchGuarantees()
    }
  }, [guaranteesLoaded, fetchGuarantees])

  useEffect(() => {
    if(!genericProductsLoaded) {
      fetchProducts('generic')
    }
  }, [genericProductsLoaded, fetchProducts])

  const onCreateClick = () => {
    setCurrentGuarantee({
      id: '',
      title: {},
      text: {},
    })
  }

  const onEditClick = (guarantee) => {
    setCurrentGuarantee(guarantee)
  }

  const onDetailSubmit = async () => {
    let response
    const data = {...currentGuarantee}
    if(!data.id) {
      response = await createGuarantee(data)
    } else {
      response = await updateGuarantee(data.id, data)
    }
    setCurrentGuarantee(null)
  }

  const onDetailDelete = async () => {
    if(!confirm(`Are you sure you want to delete this guarantee? (${currentGuarantee?.title?.fr || currentGuarantee.id})`)) return
    let response = await deleteGuarantee(currentGuarantee.id)
    setCurrentGuarantee(null)
  }

  const promptImportCollection = () => {
    setImportCollectionModalOpen(true)
  }

  return (
    <>
      <ToolbarCustom
        pageTitle={'Guarantees'}
        primaryButton={guaranteesLoaded && { onClick: onCreateClick, title: 'Add' }}
        loading={!guaranteesLoaded}
        filterElements={[(
          config.environment !== 'staging' && <button className='btn btn-sm fw-bold btn-primary' onClick={promptImportCollection}>
            <KTSVG path='/media/icons/duotune/arrows/arr037.svg' className='svg-icon-3' />
            Import from staging
          </button>
        )]}
         />
      {/* begin::Row */}
      <div className='mt-3'>
        {guaranteesLoaded && 
          <DataGrid
            rowSelection={false}
            rows={filteredGuarantees}
            columns={[{
              field: 'title',
              valueFormatter: (value) => value?.fr || 'No title',
              headerName: 'Title',
              flex: 1,
              headerClassName: 'table--header-cell',
              cellClassName: 'table--body-cell'
            }, {
              field: 'products',
              flex: 1,
              renderCell: ({ row }) => {
                return (
                  <div className='d-flex pt-1 flex-wrap gap-1'>
                    { genericProducts?.filter((product) => product.guarantees?.includes(row.id)).map((product) => (
                      <span key={product.id} className='badge badge-secondary fs-8'>{product.name?.fr}</span>
                    ))}
                  </div>
                )
              },
              headerClassName: 'table--header-cell',
              cellClassName: 'table--body-cell'
            }, {
              field: 'actions',
              type: 'actions',
              headerName: '',
              width: 100,
              cellClassName: 'actions',
              getActions: ({ id }) => {
                return [
                  <GridActionsCellItem
                    icon={<i className="bi bi-pencil-square text-primary"></i>}
                    label="Edit"
                    className="textPrimary"
                    onClick={() => onEditClick(itemWithId(filteredGuarantees, id))}
                    color="inherit"
                  />,
                ];
              },
              headerClassName: 'table--header-cell',
              cellClassName: 'table--body-cell'
            }]}
            pageSizeOptions={[20, 50, 100]}
          />
        }

        {/* begin::Col */}
        {/* <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-50 mb-5 mb-xl-10' />
          <ListsWidget26 className='h-lg-50' />
        </div> */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6'>
          {/* <EngageWidget10 className='h-md-100' /> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gx-5 gx-xl-10'>
        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-new-charts-widget8 cssclassName="h-xl-100" chartHeight="275px" [chartHeightNumber]="275"></app-new-charts-widget8> */}
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xxl-6 mb-5 mb-xl-10'>
          {/* <app-cards-widget18 cssclassName="h-xl-100" image="./assets/media/stock/600x600/img-65.jpg"></app-cards-widget18> */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 gx-xl-8'>
        <div className='col-xxl-4'>
          <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
        </div>
        <div className='col-xl-8'>
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
      <GuaranteeDetailModal
        show={Boolean(currentGuarantee)}
        handleClose={() => setCurrentGuarantee(null)}
        guarantee={currentGuarantee}
        setGuarantee={setCurrentGuarantee}
        onSubmit={onDetailSubmit}
        onDelete={onDetailDelete} />
      <ImportCollectionModal
        isOpen={importCollectionModalOpen}
        onClose={() => setImportCollectionModalOpen(false)}
        collectionName={"product_guarantees"}
        sourceEnvironment={"staging"}
        onImportComplete={async () => {
          // reload collection
          await fetchGuarantees()
        }} />
    </>
  )
}

const GuaranteesWrapper = () => {
  return (
    <>
      <GuaranteesPage />
    </>
  )
}

export default GuaranteesWrapper
