/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useContext, forwardRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { ProductsContext } from '../../context'
import DatePicker, { CalendarContainer } from "react-datepicker";
import { EditorState } from "draft-js"

import { createEditorStateFromRaw, serialiseEditorStateToRaw } from "draftail"
import Editor, { customDecorators, fromHTML, toHTML } from '../../components/Editor'

import "react-datepicker/dist/react-datepicker.css";
import ImageInput from '../../components/ImageInput';
import { ImageInputComponent } from '../../../_metronic/assets/ts/components';
import { uploadFile } from '../../services/firebase';
// import {defaultCreateAppData, ICreateAppData} from '../'
// import {StepperComponent} from '../../../assets/ts/components'
// import {KTSVG} from '../../../helpers'
// import {Step1} from './steps/Step1'
// import {Step2} from './steps/Step2'
// import {Step3} from './steps/Step3'
// import {Step4} from './steps/Step4'
// import {Step5} from './steps/Step5'

const modalsRoot = document.getElementById('root-modals') || document.body

const InfoBlockDetailModal = ({show, handleClose, infoBlock, setInfoBlock, onSubmit, onDelete}) => {
  
  
  const [selectedLanguage, setSelectedLanguage] = useState('fr')
  
  const [editorStates, setEditorStates] = useState({
    en: EditorState.createEmpty(),
    fr: EditorState.createEmpty(),
    it: EditorState.createEmpty(),
    es: EditorState.createEmpty()
  })
  
  const [newImage, setNewImage] = useState(null)

  useEffect(() => {
    setNewImage(null)
  }, [show])

  useEffect(() => {
    if(!infoBlock) {
      return
    }
    setEditorStates({
      en: createEditorStateFromRaw(fromHTML(infoBlock.text?.en || ''), customDecorators),
      fr: createEditorStateFromRaw(fromHTML(infoBlock.text?.fr || ''), customDecorators),
      it: createEditorStateFromRaw(fromHTML(infoBlock.text?.it || ''), customDecorators),
      es: createEditorStateFromRaw(fromHTML(infoBlock.text?.es || ''), customDecorators)
    })
  }, [infoBlock])

  const handleEditorChange = (content) => {
    setEditorStates({
      ...editorStates,
      [selectedLanguage]: content
    })
  }

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...infoBlock, ...fieldsToUpdate}
    setInfoBlock(updatedData)
  }

  const dateStringToDate = (dateString) => {
    if(!dateString) {
      return
      return new Date()
    }
    const components = dateString.split('/')
    return new Date(2000, parseInt(components[1]) - 1, parseInt(components[0]), 0, 0, 0, 0);
  }

  const dateToString = (date) => {
    if(!date) {
      return ''
    }
    return `${date.getDate()}/${date.getMonth() + 1}`
  }

  const onSave = async () => {
    const block = {...infoBlock}
    block.text = {
      en: toHTML(serialiseEditorStateToRaw(editorStates.en)),
      fr: toHTML(serialiseEditorStateToRaw(editorStates.fr)),
      it: toHTML(serialiseEditorStateToRaw(editorStates.it)),
      es: toHTML(serialiseEditorStateToRaw(editorStates.es)),
    }
    
    if(newImage?.base64) {
      const uploadResponse = await uploadFile({ file: { name: newImage.name, type: newImage.type, size: newImage.size, base64: newImage.base64.split(',')[1]}})
      if(uploadResponse.url) {
        block.image = uploadResponse.url
      }
    }
    onSubmit(block)
  }    

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{ infoBlock?.id ? 'Update Info Block' : 'Create Info Block' }</h2>
        {/* begin::Close */}
        {/* <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div> */}
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          <div className='mb-5'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Language</span>
            </label>
            <div className='nav-group nav-group-outline nav-group-slim'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='fr'
                  checked={selectedLanguage === 'fr'}
                  onChange={(e) => setSelectedLanguage('fr')}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  FR
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  EN
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  ES
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  IT
                </span>
              </label>
            </div>
          </div>
          {/*begin::Form */}
          <form noValidate id='kt_modal_create_app_form'>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Block title</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='block-title'
                placeholder=''
                value={infoBlock?.title?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    title: {
                      ...(infoBlock?.title || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>
                  Text
                { selectedLanguage !== "fr" && <><span>&nbsp;&nbsp;&nbsp;</span>
                  {/* <Button onClick={handleCopyContent} size="sm" className="ml-5">{'Copy from FR'}</Button> */}
                  </> }
                  {/* <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleExportContent} size="sm" className="ml-5">{'Export to HTML'}</Button></>
                  <><span>&nbsp;&nbsp;&nbsp;</span><Button onClick={handleImportContent} size="sm" className="ml-5">{'Import from HTML'}</Button></> */}
                </span>
                {/* <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your unique article title'
                ></i> */}
              </label>
              { infoBlock && <div>
                <Editor
                  editorState={editorStates[selectedLanguage]}
                  onChange={handleEditorChange}
                  entities={['link']} />
                </div>
                }
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>
                  Image
                </span>
              </label>
              <ImageInput onImageSelected={i => setNewImage(i)} imageUrl={newImage?.base64 ? newImage.base64 : infoBlock?.image} />
            </div>            

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Button Label</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='block-label'
                placeholder=''
                value={infoBlock?.button_label?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    button_label: {
                      ...(infoBlock?.button_label || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Button Link URL</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='block-link'
                placeholder=''
                value={infoBlock?.button_link?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    button_link: {
                      ...(infoBlock?.button_link || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2 d-row gap-4'>
                <span>Published</span>
                <input className='form-check-input' type='checkbox' checked={infoBlock?.published} onChange={e => updateData({
                  published: e.target.checked,
                })} />
              </label>
            </div>

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
              <div className='me-2'>
                { infoBlock?.id &&
                  <button
                    type='button'
                    className='btn btn-lg btn-danger'
                    data-kt-stepper-action='next'
                    onClick={onDelete}
                  >
                    { 'Delete' }
                  </button>
                }
              </div>                
              <div className='d-flex gap-3'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={onSave}
                >
                  { infoBlock?.id ? 'Update' : 'Create' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}


export default InfoBlockDetailModal
