import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'


const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-4 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
        </div>
      </div>

      {/* <SidebarMenuItem
        to='/products'
        icon='/media/icons/duotune/ecommerce/ecm001.svg'
        title={intl.formatMessage({id: 'MENU.PRODUCTS'})}
        fontIcon='bi-app-indicator'
      /> */}
      <SidebarMenuItem
        to='/generic-products'
        icon='/media/icons/duotune/ecommerce/ecm001.svg'
        title={intl.formatMessage({id: 'MENU.GENERIC_PRODUCTS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/specific-products'
        icon='/media/icons/duotune/ecommerce/ecm002.svg'
        title={intl.formatMessage({id: 'MENU.SPECIFIC_PRODUCTS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/local-products'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
        title={intl.formatMessage({id: 'MENU.LOCAL_PRODUCTS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/guarantees'
        icon='/media/icons/duotune/general/gen026.svg'
        title={intl.formatMessage({id: 'MENU.GUARANTEES'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/exclusions'
        icon='/media/icons/duotune/general/gen040.svg'
        title={intl.formatMessage({id: 'MENU.EXCLUSIONS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/product-hub'
        icon='/media/icons/duotune/layouts/lay007.svg'
        title={intl.formatMessage({id: 'MENU.PRODUCTS_HUB'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/product-sections'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.PRODUCT_SECTIONS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/users'
        icon='/media/icons/duotune/communication/com014.svg'
        title={intl.formatMessage({id: 'MENU.USERS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/purchases'
        icon='/media/icons/duotune/ecommerce/ecm008.svg'
        title={intl.formatMessage({id: 'MENU.PURCHASES'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/purchase-orders'
        icon='/media/icons/duotune/finance/fin001.svg'
        title={intl.formatMessage({id: 'MENU.PURCHASE_ORDERS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/vouchers'
        icon='/media/icons/duotune/ecommerce/ecm003.svg'
        title={intl.formatMessage({id: 'MENU.VOUCHERS'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-4 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
        </div>
      </div>

      <SidebarMenuItem
        to='/domains'
        icon='/media/icons/duotune/general/gen002.svg'
        title={intl.formatMessage({id: 'MENU.DOMAINS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/durations'
        icon='/media/icons/duotune/general/gen012.svg'
        title={intl.formatMessage({id: 'MENU.DURATIONS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/resorts'
        icon='/media/icons/duotune/maps/map007.svg'
        title={intl.formatMessage({id: 'MENU.RESORTS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/regions'
        icon='/media/icons/duotune/maps/map002.svg'
        title={intl.formatMessage({id: 'MENU.REGIONS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/network'
        icon='/media/icons/duotune/ecommerce/ecm004.svg'
        title={intl.formatMessage({id: 'MENU.NETWORK'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/partners'
        icon='/media/icons/duotune/coding/cod007.svg'
        title={intl.formatMessage({id: 'MENU.PARTNERS'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/countries'
        icon='/media/icons/duotune/maps/map004.svg'
        title={intl.formatMessage({id: 'MENU.COUNTRIES'})}
        fontIcon='bi-app-indicator'
      /> */}
      
      <div className='menu-item'>
        <div className='menu-content pt-4 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
        </div>
      </div>

      <SidebarMenuItem
        to='/article-hub'
        icon='/media/icons/duotune/layouts/lay007.svg'
        title={intl.formatMessage({id: 'MENU.ARTICLES_HUB'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/article-sections'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.ARTICLE_SECTIONS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/articles'
        icon='/media/icons/duotune/general/gen005.svg'
        title={intl.formatMessage({id: 'MENU.ARTICLES'})}
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/news'
        icon='/media/icons/duotune/general/gen005.svg'
        title={intl.formatMessage({id: 'MENU.NEWS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/info-blocks'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.INFO_BLOCKS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/faqs'
        icon='/media/icons/duotune/general/gen046.svg'
        title={intl.formatMessage({id: 'MENU.FAQS'})}
        fontIcon='bi-app-indicator'
      />
      
      <div className='menu-item'>
        <div className='menu-content pt-4 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
        </div>
      </div>

      <SidebarMenuItem
        to='/contact'
        icon='/media/icons/duotune/communication/com003.svg'
        title={intl.formatMessage({id: 'MENU.CONTACT_PAGE'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/commercial-contact'
        icon='/media/icons/duotune/communication/com003.svg'
        title={intl.formatMessage({id: 'MENU.COMMERCIAL_CONTACT_PAGE'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/requests'
        icon='/media/icons/duotune/communication/com003.svg'
        title={intl.formatMessage({id: 'MENU.CONTACT_FORMS'})}
        fontIcon='bi-app-indicator'
      />
      
      <div className='menu-item'>
        <div className='menu-content pt-4 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'></span>
        </div>
      </div>
      
      <SidebarMenuItem
        to='/statistics'
        icon='/media/icons/duotune/general/gen032.svg'
        title={intl.formatMessage({id: 'MENU.STATISTICS'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-4 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-4 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}
      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
