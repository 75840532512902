import { SET_TOAST } from '../types';

const articlesReducer = (state, action) => {
  switch(action.type) {
    case SET_TOAST:
      return{
        ...state,
        toast: action.payload,
      }
    default:
      return state;
  }
}

export default articlesReducer