import React, { useReducer } from 'react'
import { ARTICLES, ARTICLE_SECTIONS } from '../../constants/collections'
import { createDocument, deleteDocument, fetchCollection, updateDocument } from '../../services/firebase'
import { SET_ARTICLES, SET_ARTICLE_SECTIONS } from '../types'
import articlesReducer from './articlesReducer'
import { CONTENT_PAGE_ARTICLE_IDS } from '../../utils'

const ArticlesContext = React.createContext()

const ArticlesState = ({ children }) => {

  const initialState = {
    articleSections: [],
    articleSectionsLoaded: false,
    articles: [],
    articlesLoaded: false
  }

  const [state, dispatch] = useReducer(articlesReducer, initialState)

  const fetchArticleSections = async () => {
    const snapshot = await fetchCollection(ARTICLE_SECTIONS)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setArticleSections(array)
  }

  const createArticleSection = async (data) => {
    try {
      const snapshot = await createDocument(ARTICLE_SECTIONS, data)
      setArticleSections([
        ...state.articleSections,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateArticleSection = async (articleSectionId, data) => {
    try {
      await updateDocument(`${ARTICLE_SECTIONS}/${articleSectionId}`, data)
      let articleSectionsArray = [...state.articleSections]
      let index = articleSectionsArray.findIndex(s => s.id === articleSectionId)
      articleSectionsArray[index] = {
        ...articleSectionsArray[index],
        ...data
      }
      setArticleSections(articleSectionsArray)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteArticleSection = async (articleSectionId) => {
    try {
      await deleteDocument(`${ARTICLE_SECTIONS}/${articleSectionId}`)
      let articleSectionsArray = [...state.articleSections]
      let index = articleSectionsArray.findIndex(s => s.id === articleSectionId)
      articleSectionsArray.splice(index, 1)
      setArticleSections(articleSectionsArray)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }


  const fetchArticles = async () => {
    const snapshot = await fetchCollection(ARTICLES)
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    setArticles(array)
  }

  const createArticle = async (data) => {
    try {
      const snapshot = await createDocument(ARTICLES, data)
      setArticles([
        ...state.articles,
        { ...data, id: snapshot.id }
      ])
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateArticle = async (id, data) => {
    try {
      await updateDocument(`${ARTICLES}/${id}`, data)
      let array = [...state.articles]
      let index = array.findIndex(s => s.id === id)
      if(index === -1) {
        array.push({
          ...data,
          id
        })
      } else {
        array[index] = {
          ...array[index],
          ...data
        }
      }
      setArticles(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteArticle = async (id) => {
    try {
      await deleteDocument(`${ARTICLES}/${id}`)
      let array = [...state.articles]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      setArticles(array)
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const setArticleSections = (value) => {
    dispatch({
      type: SET_ARTICLE_SECTIONS,
      payload: value
    })
  }

  const setArticles = (value) => {
    value.sort((a, b) => {
      if(a.title?.fr?.trim().toLowerCase() < b.title?.fr?.trim().toLowerCase()) { return -1 }
      if(a.title?.fr?.trim().toLowerCase() > b.title?.fr?.trim().toLowerCase()) { return 1 }
      return 0
    })
    dispatch({
      type: SET_ARTICLES,
      payload: value
    })
  } 

  return (
    <ArticlesContext.Provider value={{
      articleSections: state.articleSections,
      articleSectionsLoaded: state.articleSectionsLoaded,
      articles: state.articles.filter(a => !CONTENT_PAGE_ARTICLE_IDS.includes(a.id)),
      contentArticles: state.articles.filter(a => CONTENT_PAGE_ARTICLE_IDS.includes(a.id)),
      articlesLoaded: state.articlesLoaded,
      fetchArticleSections,
      createArticleSection,
      updateArticleSection,
      deleteArticleSection,
      fetchArticles,
      createArticle,
      updateArticle,
      deleteArticle
    }}>
      { children }
    </ArticlesContext.Provider>
  )
}

export { ArticlesContext }

export default ArticlesState