import moment from 'moment'
import React, { useContext, useEffect, useState} from 'react'
import ToolbarCustom from '../../../_metronic/layout/components/toolbar/toolbars/ToolbarCustom'
import {
  ChartsWidget1,
  ChartsWidget2,
  ChartsWidget3,
  ChartsWidget4,
  ChartsWidget5,
  ChartsWidget6,
  ChartsWidget7,
  ChartsWidget8,
} from '../../../_metronic/partials/widgets'
import { CustomersContext, ProductsContext } from '../../context'
import AreaChart from './AreaChart'
import ColumnsChart from './ColumnsChart'
import TableChart from './TableChart'

const Statistics = () => {
    
  const {
    customers,
    customersLoaded,
    fetchCustomers,
    purchases,
    purchasesLoaded,
    fetchPurchases,
  } = useContext(CustomersContext)

  const {
    resorts,
    resortsLoaded,
    fetchResorts,
    domains,
    domainsLoaded,
    fetchDomains,
    durations,
    durationsLoaded,
    fetchDurations
  } = useContext(ProductsContext)

  const [validPurchases, setValidPurchases] = useState([])

  const [salesChartData, setSalesChartData] = useState({ "7days": { series: [], xAxisLabels: [] }, "30days": { series: [], xAxisLabels: [] }, "year": { series: [], xAxisLabels: [] }})
  const [revenueChartData, setRevenueChartData] = useState({ "7days": { series: [], xAxisLabels: [] }, "30days": { series: [], xAxisLabels: [] }, "year": { series: [], xAxisLabels: [] }})
  const [resortsChartData, setResortsChartData] = useState([])
  const [domainsChartData, setDomainsChartData] = useState([])
  const [durationsChartData, setDurationsChartData] = useState([])
  const [languagesChartData, setLanguagesChartData] = useState([])

  useEffect(() => {
    if(!customersLoaded) {
      fetchCustomers()
    }
  }, [customersLoaded, fetchCustomers])

  useEffect(() => {
    if(!purchasesLoaded) {
      fetchPurchases()
    }
  }, [purchasesLoaded, fetchPurchases])


  useEffect(() => {
    if(!resortsLoaded) {
      fetchResorts()
    }
  }, [resortsLoaded, fetchResorts])

  useEffect(() => {
    if(!domainsLoaded) {
      fetchDomains()
    }
  }, [domainsLoaded, fetchDomains])

  useEffect(() => {
    if(!durationsLoaded) {
      fetchDurations()
    }
  }, [durationsLoaded, fetchDurations])

  useEffect(() => {
    let vp = []
    for(let p of purchases) {
      if(p.status === 'paid' || p.status === 'refunded') {
        vp.push(p)
      }
    }
    vp.sort((a, b) => {
      if(a.paid_at < b.paid_at) return 1
      if(a.paid_at > b.paid_at) return -1
      return 0
    })
    setValidPurchases([...vp])
  }, [purchases])


  useEffect(() => {

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

    const salesData = {
      "7days": {
        series: [{
          name: 'Products sold',
          data: [],
        // }, {
        //   name: 'Purchases cancelled',
        //   data: [],
        }],
        xAxisLabels: []
      },
      "30days": {
        series: [{
          name: 'Products sold',
          data: [],
        // }, {
        //   name: 'Purchases cancelled',
        //   data: [],
        }],
        xAxisLabels: []
      },
      "year": {
        series: [{
          name: 'Products sold',
          data: [],
        // }, {
        //   name: 'Purchases cancelled',
        //   data: [],
        }],
        xAxisLabels: []
      }
    }
    const revenueData = {
      "7days": {
        series: [{
          name: 'Revenue generated',
          data: [],
        }],
        xAxisLabels: []
      },
      "30days": {
        series: [{
          name: 'Revenue generated',
          data: [],
        }],
        xAxisLabels: []
      },
      "year": {
        series: [{
          name: 'Revenue generated',
          data: [],
        }],
        xAxisLabels: []
      }
    } 
    const relevantPurchases = validPurchases.filter(p => p.status === 'paid')
    for(let i = 0; i < 7; i++) {
      const date = moment().subtract(i, 'days')
      salesData['7days'].xAxisLabels.push(date.format('DD/MM/YYYY'))
      revenueData['7days'].xAxisLabels.push(date.format('DD/MM/YYYY'))
      let soldAmount = 0
      let revenue = 0
      for(let p of relevantPurchases) {
        if(moment(p.meta.updated).isSame(date, 'date')) {
          soldAmount++
          revenue += p.amount
        }
      }
      salesData['7days'].series[0].data.push(soldAmount)
      revenueData['7days'].series[0].data.push(revenue / 100)
      // data.series[1].data.push(cancelledAmount)
    }
    for(let i = 0; i < 30; i++) {
      const date = moment().subtract(i, 'days')
      salesData['30days'].xAxisLabels.push(date.format('DD/MM/YYYY'))
      revenueData['30days'].xAxisLabels.push(date.format('DD/MM/YYYY'))
      let soldAmount = 0
      let revenue = 0
      for(let p of relevantPurchases) {
        if(moment(p.meta.updated).isSame(date, 'date')) {
          soldAmount++
          revenue += p.amount
        }
      }
      salesData['30days'].series[0].data.push(soldAmount)
      revenueData['30days'].series[0].data.push(revenue / 100)
      // data.series[1].data.push(cancelledAmount)
    }
    for(let i = 0; i < 12; i++) {
      const date = moment().subtract(i, 'months')
      salesData['year'].xAxisLabels.push(date.format('MMMM'))
      revenueData['year'].xAxisLabels.push(date.format('MMMM'))
      let soldAmount = 0
      let revenue = 0
      for(let p of relevantPurchases) {
        if(moment(p.meta.updated).isSame(date, 'month')) {
          soldAmount++
          revenue += p.amount
        }
      }
      salesData['year'].series[0].data.push(soldAmount)
      revenueData['year'].series[0].data.push(revenue / 100)
      // data.series[1].data.push(cancelledAmount)
    }

    for(let key in salesData) {
      salesData[key].xAxisLabels.reverse()
      salesData[key].series[0].data.reverse()
    }

    for(let key in revenueData) {
      revenueData[key].xAxisLabels.reverse()
      revenueData[key].series[0].data.reverse()
    }
    setSalesChartData(salesData)
    setRevenueChartData(revenueData)

    const resortCounts = {}
    const domainCounts = {}
    const durationCounts = {}
    const languageCounts = {}

    for(let p of relevantPurchases) {
      if(!resortCounts[p.resort]) {
        resortCounts[p.resort] = 0
      }
      resortCounts[p.resort]++

      if(!domainCounts[p.domain]) {
        domainCounts[p.domain] = 0
      }
      domainCounts[p.domain]++

      if(!durationCounts[p.duration]) {
        durationCounts[p.duration] = 0
      }
      durationCounts[p.duration]++

      if(!languageCounts[p.language]) {
        languageCounts[p.language] = 0
      }
      languageCounts[p.language]++
    }
    
    const resortsData = []
    for(const key in resortCounts) {
      const resort = itemWithId(resorts, key)
      if(!resort) continue
      resortsData.push({
        label: resort.name.fr,
        count: resortCounts[key]
      })
    }
    resortsData.sort((a, b) => {
      if(a.count < b.count) return 1
      if(a.count > b.count) return -1
      
      if(a.label.toLowerCase() < b.label.toLowerCase()) return 1
      if(a.label.toLowerCase() > b.label.toLowerCase()) return -1

      return 0
    })
    setResortsChartData(resortsData)

    const domainsData = []
    for(const key in domainCounts) {
      const domain = itemWithId(domains, key)
      if(!domain) continue
      domainsData.push({
        label: domain.title.fr,
        count: domainCounts[key]
      })
    }
    domainsData.sort((a, b) => {
      if(a.count < b.count) return 1
      if(a.count > b.count) return -1
      
      if(a.label.toLowerCase() < b.label.toLowerCase()) return 1
      if(a.label.toLowerCase() > b.label.toLowerCase()) return -1

      return 0
    })
    setDomainsChartData(domainsData)

    const languagesData = []
    for(const key in languageCounts) {
      // const resort = itemWithId(resorts, key)
      // if(!resort) continue
      languagesData.push({
        label: key,
        count: languageCounts[key]
      })
    }
    languagesData.sort((a, b) => {
      if(a.count < b.count) return 1
      if(a.count > b.count) return -1
      
      if(a.label.toLowerCase() < b.label.toLowerCase()) return 1
      if(a.label.toLowerCase() > b.label.toLowerCase()) return -1

      return 0
    })
    setLanguagesChartData(languagesData)
    
  }, [validPurchases, resorts, domains])

  const totalRevenue = () => {
    let revenue = 0
    for(let p of validPurchases) {
      if(p.status === 'paid' || p.status === 'refunded') {

        revenue += p.amount - (p.amount_refunded ? p.amount_refunded : 0)
      }
    }
    return revenue
  }

  return (
    <>
      <ToolbarCustom pageTitle={'Statistics'} loading={!purchasesLoaded || !customersLoaded} />
      {/* begin::Row */}
      <div className='row g-5 g-xl-8 mt-1'>
        <div className='col-xl-6'>
          <ColumnsChart
            className='card-xl-stretch mb-xl-8'
            chartType='bar'
            title="Number of insurance sold"
            // baseColor="#00f"
            subtitle={`All time total: ${validPurchases.filter(p => p.status === 'paid').length}`}
            data={salesChartData}
            filters={[{ value: '7days', label: "Last 7 days"}, { value: '30days', label: "Last 30 days"}, { value: 'year', label: "Last year"}]}
          />
        </div>
        <div className='col-xl-6'>
          <ColumnsChart
            className='card-xl-stretch mb-xl-8'
            title="Revenue generated"
            chartType='bar'
            subtitle={`All time total: ${(totalRevenue()/100).toFixed(2)}€`}
            data={revenueChartData}
            baseColor="#8c0"
            // lightColor="#afa"
            yFormatter={val => `${val.toFixed(2)}€`}
            filters={[{ value: '7days', label: "Last 7 days"}, { value: '30days', label: "Last 30 days"}, { value: 'year', label: "Last year"}]}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <TableChart
            className='card-xl-stretch mb-xl-8'
            title="Popular resorts"
            subtitle="Purchases per resort"
            data={resortsChartData}
          />
        </div>
        <div className='col-xl-6'>
          <TableChart
            className='card-xl-stretch mb-xl-8'
            title="Popular activities"
            subtitle="Purchases per activity"
            data={domainsChartData}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          {/* <ChartsWidget5 className='card-xl-stretch mb-xl-8' /> */}
          <TableChart
            className='card-xl-stretch mb-xl-8'
            title="Languages"
            subtitle="Purchases per language"
            data={languagesChartData}
          />
        </div>
        <div className='col-xl-6'>
          {/* <ChartsWidget6 className='card-xl-stretch mb-5 mb-xl-8' /> */}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row g-5 g-xl-8'>
        <div className='col-xl-6'>
          <ChartsWidget7 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-6'>
          <ChartsWidget8 className='card-xl-stretch mb-5 mb-xl-8' />
        </div>
      </div> */}
      {/* end::Row */}
    </>
  )
}

export default Statistics
