import React, { useReducer } from 'react'
import { SET_TOAST } from '../types'
import notificationsReducer from './notificationsReducer'

const NotificationsContext = React.createContext()

const NotificationsState = ({ children }) => {

  const initialState = {
    toast: {
      isOpen: false,
      message: '',
      type: 'info'
    }
  }

  const [state, dispatch] = useReducer(notificationsReducer, initialState)

  const toast = ({ message, type, timeout = 4000 }) => {
    dispatch({
      type: SET_TOAST,
      payload: {
        isOpen: true,
        message,
        type
      }
    })

    setTimeout(() => {
      dispatch({
        type: SET_TOAST,
        payload: {
          isOpen: false,
          message,
          type
        }
      })
    }, timeout)
  }

  return (
    <NotificationsContext.Provider value={{
      toast,
    }}>
      { children }
      <div className={`toastr-container`}>
        <div className={`toastr toastr-${state.toast.type} ${state.toast.isOpen ? '' : 'hidden'}`}>
          <div className="toastr-message">
            {state.toast.message}
          </div>
        </div>
      </div>
    </NotificationsContext.Provider>
  )
}

export { NotificationsContext }

export default NotificationsState