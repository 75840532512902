import { SET_FAQS, SET_INFO_BLOCKS, SET_NEWS } from '../types';

const contentReducer = (state, action) => {
  switch(action.type) {
    case SET_NEWS:
      return {
        ...state,
        news: action.payload,
        newsLoaded: true
      }
    case SET_INFO_BLOCKS:
      return {
        ...state,
        infoBlocks: action.payload,
        infoBlocksLoaded: true
      }
    case SET_FAQS:
      return {
        ...state,
        faqs: action.payload,
        faqsLoaded: true
      }
    default:
      return state;
  }
}

export default contentReducer