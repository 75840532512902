import { ADD_PAYMENT_REQUEST, SET_CUSTOMERS, SET_PURCHASES, SET_PURCHASE_ORDERS } from '../types';

const customersReducer = (state, action) => {
  switch(action.type) {
    case SET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
        customersLoaded: true
      }
    case SET_PURCHASES:
      return {
        ...state,
        purchases: action.payload,
        purchasesLoaded: true
      }
    case SET_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: action.payload,
        purchaseOrdersLoaded: true
      }
    case ADD_PAYMENT_REQUEST:
      return {
        ...state,
        paymentRequests: [
          ...state.paymentRequests,
          action.payload
        ]
      }
    default:
      return {
        ...state
      }
  }
}

export default customersReducer