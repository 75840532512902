/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useContext} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { ProductsContext } from '../../context'
// import {defaultCreateAppData, ICreateAppData} from '../'
// import {StepperComponent} from '../../../assets/ts/components'
// import {KTSVG} from '../../../helpers'
// import {Step1} from './steps/Step1'
// import {Step2} from './steps/Step2'
// import {Step3} from './steps/Step3'
// import {Step4} from './steps/Step4'
// import {Step5} from './steps/Step5'

const modalsRoot = document.getElementById('root-modals') || document.body

const RegionDetailModal = ({show, handleClose, area, setRegion, onSubmit, onDelete}) => {

  const { countries } = useContext(ProductsContext)

  const [selectedLanguage, setSelectedLanguage] = useState('fr')

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...area, ...fieldsToUpdate}
    setRegion(updatedData)
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{ area?.id ? 'Update Region' : 'Create Region' }</h2>
        {/* begin::Close */}
        {/* <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div> */}
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          <div className='mb-5'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Language</span>
            </label>
            <div className='nav-group nav-group-outline nav-group-slim'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='fr'
                  checked={selectedLanguage === 'fr'}
                  onChange={(e) => setSelectedLanguage('fr')}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  FR
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  EN
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  ES
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  IT
                </span>
              </label>
            </div>
          </div>
          {/*begin::Form */}
          <form noValidate id='kt_modal_create_app_form'>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Region name</span>
                {/* <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your unique area title'
                ></i> */}
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='area-name'
                placeholder=''
                value={area?.name[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    name: {
                      ...(area?.name || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Country</label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  value={area?.country || ''}
                  onChange={e => updateData({
                    country: e.target.value
                  })}
                >
                  <option disabled value=''>Select a country..</option>
                  { countries.map((country) =>
                    <option key={`country_option_${country.code}`} value={country.code}>
                    { country.name }
                    </option>
                  )}
                </select>
              </div>
            </div>

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
              <div className='me-2'>
                { area?.id &&
                  <button
                    type='button'
                    className='btn btn-lg btn-danger'
                    data-kt-stepper-action='next'
                    onClick={onDelete}
                  >
                    { 'Delete' }
                  </button>
                }
              </div>                
              <div className='d-flex gap-3'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={onSubmit}
                >
                  { area?.id ? 'Update' : 'Create' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default RegionDetailModal
