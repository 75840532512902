/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useContext, forwardRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { ProductsContext } from '../../context'
import DPicker from '../../components/DatePicker'

const modalsRoot = document.getElementById('root-modals') || document.body

const ResortDetailModal = ({show, handleClose, resort, setResort, onSubmit, onDelete}) => {

  const { regions, products, resortPartners, resortPartnersLoaded, fetchResortPartners } = useContext(ProductsContext)

  const [productMatches, setProductMatches] = useState([])

  console.log("resort", resort)

  useEffect(() => {
    if(resortPartnersLoaded) {
      return
    }
    fetchResortPartners()
  }, [resortPartnersLoaded])

  useEffect(() => {
    if(!products) {
      return
    }
    const matches = products.map(product => {
      for(let optionKey in product.options) {
        let [resortId] = optionKey.split('/')
        if(resortId === resort?.id) {
          return { product: product.id, price: product.options[optionKey].price }
        }
      }
      return null
    }).filter(Boolean)
    setProductMatches(matches)
  }, [products, resort])

  const [selectedLanguage, setSelectedLanguage] = useState('fr')

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...resort, ...fieldsToUpdate}
    setResort(updatedData)
  }

  const handleAddProduct = () => {
    setProductMatches([
      {
        product: '',
        price: 0,
      },
      ...productMatches
    ])
  }
  const handleProductChange = (fieldsToUpdate, index) => {
    const matches = productMatches
    matches[index] = {
      ...matches[index],
      ...fieldsToUpdate
    }
    setProductMatches([...matches])
  }

  const handleRemoveProduct = (index) => {
    const matches = productMatches
    matches.splice(index, 1)
    setProductMatches([...matches])
  }

  const handleSubmit = () => {
    onSubmit(resort, productMatches)
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{ resort?.id ? 'Update Resort' : 'Create Resort' }</h2>
        {/* begin::Close */}
        {/* <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div> */}
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          <div className='mb-5'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Language</span>
            </label>
            <div className='nav-group nav-group-outline nav-group-slim'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='fr'
                  checked={selectedLanguage === 'fr'}
                  onChange={(e) => setSelectedLanguage('fr')}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  FR
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  EN
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  ES
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  IT
                </span>
              </label>
            </div>
          </div>
          {/*begin::Form */}
          <form noValidate id='kt_modal_create_app_form'>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>{`Resort name (${selectedLanguage})`}</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='resort-name'
                placeholder=''
                value={resort?.name?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    name: {
                      ...(resort?.name || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>{`Description (${selectedLanguage})`}</span>
              </label>
              <textarea
                className='form-control form-control-lg form-control-solid'
                name='resort-description'
                placeholder=''
                value={resort?.description?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    description: {
                      ...(resort?.description || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
              {/* <input
                type='textearea'
                className='form-control form-control-lg form-control-solid'
                name='resort-description'
                placeholder=''
                value={resort?.description?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    description: {
                      ...(resort?.description || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              /> */}
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Slug (generated)</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='resort-url'
                placeholder=''
                disabled
                value={resort?.slug || ''}
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Resort url</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='resort-url'
                placeholder=''
                value={resort?.url || ''}
                onChange={(e) =>
                  updateData({
                    url: e.target.value,
                  })
                }
              />
            </div>
            
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2 d-row gap-4'>
              <span>Winter season</span>
              <input className='form-check-input' type='checkbox' checked={resort?.winter_season?.enabled} onChange={e => updateData({
                winter_season: {
                  ...(resort?.winter_season || {}),
                  enabled: e.target.checked,
                }
              })} />
            </label>
            <div className='d-flex flex-row gap-3'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Start</span>
                </label>
                <DPicker disabled={!resort?.winter_season?.enabled} selected={resort?.winter_season?.start} onChange={(date) => {
                  updateData({
                    winter_season: {
                      ...(resort?.winter_season || {}),
                      start: date,
                    },
                  })
                }} />
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>End</span>
                </label>
                <DPicker disabled={!resort?.winter_season?.enabled} selected={resort?.winter_season?.end} onChange={(date) => {
                  updateData({
                    winter_season: {
                      ...(resort?.winter_season || {}),
                      end: date,
                    },
                  })
                }} />
              </div>
            </div>
            
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2 d-row gap-4'>
              <span>Summer season</span>
              <input className='form-check-input' type='checkbox' checked={resort?.summer_season?.enabled} onChange={e => updateData({
                summer_season: {
                  ...(resort?.summer_season || {}),
                  enabled: e.target.checked,
                }
              })} />
            </label>
            <div className='d-flex flex-row gap-3'>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Start</span>
                </label>
                <DPicker disabled={!resort?.summer_season?.enabled} selected={resort?.summer_season?.start} onChange={(date) => {
                  updateData({
                    summer_season: {
                      ...(resort?.summer_season || {}),
                      start: date,
                    },
                  })
                }} />
              </div>
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>End</span>
                </label>
                <DPicker disabled={!resort?.summer_season?.enabled} selected={resort?.summer_season?.end} onChange={(date) => {
                  updateData({
                    summer_season: {
                      ...(resort?.summer_season || {}),
                      end: date,
                    },
                  })
                }} />
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Coordinates</span>
              </label>
              <div className='d-flex flex-row gap-3'>
                <div>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Latitude</span>
                  </label>
                  <input
                    type="number"
                    className='form-control form-control-lg form-control-solid'
                    name='resort-latitude'
                    placeholder=''
                    value={resort?.coordinates?.latitude || 0}
                    onChange={(e) =>
                      updateData({
                        coordinates: {
                          ...(resort?.coordinates || {}),
                          latitude: parseFloat(e.target.value)
                        }
                      })
                    }
                  />
                </div>
                <div>
                  <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                    <span className='required'>Longitude</span>
                  </label>
                  <input
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    name='resort-longitude'
                    placeholder=''
                    value={resort?.coordinates?.longitude || 0}
                    onChange={(e) =>
                      updateData({
                        coordinates: {
                          ...(resort?.coordinates || {}),
                          longitude: parseFloat(e.target.value)
                        }
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Region</label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  value={resort?.region || ''}
                  onChange={e => updateData({
                    region: e.target.value
                  })}
                >
                  <option disabled value=''>Select region..</option>
                  { regions.map((region) =>
                    <option key={`country_option_${region.id}`} value={region.id}>
                    { region.name?.[selectedLanguage] }
                    </option>
                  )}
                </select>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Partner</label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg'
                  value={resort?.partner || ''}
                  onChange={e => updateData({
                    partner: e.target.value
                  })}
                >
                  <option value=''>- None -</option>
                  { resortPartners.map((partner) =>
                    <option key={`partner_option_${partner.id}`} value={partner.id}>
                    { partner.name?.fr }
                    </option>
                  )}
                </select>
              </div>
            </div>

            {/* <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Products&nbsp;&nbsp;</span>
                <button
                  type='button'
                  className='btn btn-sm btn-primary ml-3'
                  data-kt-stepper-action='next'
                  onClick={handleAddProduct}
                >
                  Add product
                </button>
              </label>
              <div className='resorts-table mt-5'>
                { productMatches.map((productMatch, matchIndex) =>
                  <div className='option-row border-top pt-2 pb-2' key={`product_row_${matchIndex}`}>
                    <div>
                      <span>Product</span>
                      <select name="product" id="product" value={productMatch.product} onChange={e => handleProductChange({ product: e.target.value }, matchIndex)}>
                        <option disabled value="">{"Select product"}</option>
                        { products.sort((a, b) => {
                          if(a.name.fr < b.name.fr) return -1
                          if(a.name.fr > b.name.fr) return 1
                          return 0
                        }).map((r, ri) => 
                          <option key={`product_option_${ri}`} value={r.id}>{r.name.fr}</option>
                        )}
                      </select>
                    </div>
                    <div>
                      <span>Price</span>
                      <input
                        type='number'
                        className='form-control form-control-sm form-control-solid w-100px'
                        name='resort-price'
                        placeholder=''
                        value={productMatch.price || ""}
                        onChange={e => handleProductChange({ price: parseFloat(e.target.value) }, matchIndex)}
                      />
                    </div>
                    <div>
                      <button
                        type='button'
                        className='icon-button'
                        data-kt-stepper-action='next'
                        onClick={() => handleRemoveProduct(matchIndex)}
                      >
                        <img src="/media/icons/duotune/general/gen027.svg" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div> */}

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>{`External URL (${selectedLanguage})`}</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='resort-url'
                placeholder=''
                value={resort?.external_url?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    external_url: {
                      ...(resort?.external_url || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
              <div className='me-2'>
                { resort?.id &&
                  <button
                    type='button'
                    className='btn btn-lg btn-danger'
                    data-kt-stepper-action='next'
                    onClick={onDelete}
                  >
                    { 'Delete' }
                  </button>
                }
              </div>                
              <div className='d-flex gap-3'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={handleSubmit}
                >
                  { resort?.id ? 'Update' : 'Create' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}


export default ResortDetailModal
