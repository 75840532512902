/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useContext, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { ProductsContext } from '../../context'
import ImageInput from '../../components/ImageInput'
import { uploadFile } from '../../services/firebase'
// import {defaultCreateAppData, ICreateAppData} from '../'
// import {StepperComponent} from '../../../assets/ts/components'
// import {KTSVG} from '../../../helpers'
// import {Step1} from './steps/Step1'
// import {Step2} from './steps/Step2'
// import {Step3} from './steps/Step3'
// import {Step4} from './steps/Step4'
// import {Step5} from './steps/Step5'

const modalsRoot = document.getElementById('root-modals') || document.body

const PartnerDetailModal = ({show, handleClose, resortPartner, setResortPartner, onSubmit, onDelete}) => {

  const { resorts, resortsLoaded } = useContext(ProductsContext)
  const [selectedLanguage, setSelectedLanguage] = useState('fr')
  const [newImage, setNewImage] = useState(null)

  const [filteredResorts, setFilteredResorts] = useState([])
  const [resortMatches, setResortMatches] = useState([])
  const [resortSearch, setResortSearch] = useState('')

  useEffect(() => {
    let fr = resorts.filter(resort => resort.partner === resortPartner?.id)
    if(resortSearch.length > 0) {
      fr = fr.filter(resort => resort.name.fr.toLowerCase().includes(resortSearch.toLowerCase()))
      setFilteredResorts(fr)
    } else {
      setFilteredResorts([])
    }
  }, [resortSearch, resortPartner])

  useEffect(() => {
    if(!resortsLoaded) return
    if(!resortPartner) return
    setResortMatches(resorts.filter(resort => resort.partner === resortPartner.id).map(resort => resort.id))
  }, [resortsLoaded, resortPartner, resorts])

  useEffect(() => {
    setNewImage(null)
  }, [])

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...resortPartner, ...fieldsToUpdate}
    setResortPartner(updatedData)
  }

  const onSave = async () => {
    const partner = {...resortPartner}
    if(newImage?.base64) {
      const uploadResponse = await uploadFile({ file: { name: newImage.name, type: newImage.type, size: newImage.size, base64: newImage.base64.split(',')[1]}})
      if(uploadResponse.url) {
        partner.image = uploadResponse.url
      }
    }
    onSubmit(partner)
  }

  const handleAddResort = () => {
    const newResortMatch = {
      
    }
    setResortMatches([...resortMatches, newResortMatch])
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h2>{ resortPartner?.id ? 'Update Partner' : 'Create Partner' }</h2>
        {/* begin::Close */}
        {/* <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div> */}
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          <div className='mb-5'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Language</span>
            </label>
            <div className='nav-group nav-group-outline nav-group-slim'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='fr'
                  checked={selectedLanguage === 'fr'}
                  onChange={(e) => setSelectedLanguage('fr')}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  FR
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  EN
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  ES
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  IT
                </span>
              </label>
            </div>
          </div>
          {/*begin::Form */}
          <form noValidate id='kt_modal_create_app_form'>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>{`Name (${selectedLanguage})`}</span>
                {/* <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your unique resortPartner title'
                ></i> */}
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='resortPartner-company-name'
                placeholder=''
                value={resortPartner?.name?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    name: {
                      ...(resortPartner?.name || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>{`Description (${selectedLanguage})`}</span>                
              </label>
              <textarea
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='partner-description'
                placeholder=''
                value={resortPartner?.description?.[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    description: {
                      ...(resortPartner?.description || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Image</span>
              </label>                
              <ImageInput onImageSelected={i => setNewImage(i)} imageUrl={newImage?.base64 ? newImage.base64 : resortPartner?.image} onRemove={() => {
                setNewImage(null)
                updateData({
                  image: null,
                })
              }} />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span className='required'>Link</span>
                {/* <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your unique resortPartner title'
                ></i> */}
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='resortPartner-link'
                placeholder=''
                value={resortPartner?.url || ''}
                onChange={(e) =>
                  updateData({
                    url: e.target.value,
                  })
                }
              />
            </div>    

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                {/* <span className='required'>Resorts</span> */}
                <span>Partner is listed in following resorts</span>
              </label>
              {/* <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='reseller-resorts'
                placeholder=''
                value={resortSearch}
                onChange={(e) => {
                  setResortSearch(e.target.value)
                }}
              /> */}
              <div className='fv-row mb-10'>
                {/* <div className='d-flex flex-wrap'>
                  { filteredResorts.map((resort, resortIndex) =>
                    <div key={`resort_${resort.id}`} className='btn btn-sm btn-light btn-active-primary m-1' onClick={() => {
                      console.log('select resort', resort)
                      updateData({
                        resorts: [...(reseller.resorts || []), resort.id]
                      })
                      setResortSearch('')
                    }}>
                      { resort.name.fr }
                    </div>
                  )}
                </div> */}

                <div className='d-flex flex-wrap'>
                  { resortMatches?.map((resortId, resortIndex) => {
                    const resort = resorts.find(resort => resort.id === resortId)
                    return (
                      <div key={`resort_${resort.id}`} className='btn btn-sm btn-primary m-1 disabled' >
                        { resort.name.fr }                    
                        {/* <span className='btn btn-sm btn-icon btn-active-color-primary ms-2' onClick={() => {
                        updateData({
                          resorts: reseller.resorts.filter(id => id !== resortId)
                        })
                      }}>
                          <i className='fas fa-times'></i>
                        </span> */}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>      

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
              <div className='me-2'>
                { resortPartner?.id &&
                  <button
                    type='button'
                    className='btn btn-lg btn-danger'
                    data-kt-stepper-action='next'
                    onClick={onDelete}
                  >
                    { 'Delete' }
                  </button>
                }
              </div>                
              <div className='d-flex gap-3'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={onSave}
                >
                  { resortPartner?.id ? 'Update' : 'Create' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default PartnerDetailModal
