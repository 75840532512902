/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useContext, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { CustomersContext, ProductsContext } from '../../context'
import moment from 'moment'
// import {defaultCreateAppData, ICreateAppData} from '../'
// import {StepperComponent} from '../../../assets/ts/components'
// import {KTSVG} from '../../../helpers'
// import {Step1} from './steps/Step1'
// import {Step2} from './steps/Step2'
// import {Step3} from './steps/Step3'
// import {Step4} from './steps/Step4'
// import {Step5} from './steps/Step5'

const modalsRoot = document.getElementById('root-modals') || document.body

const PurchaseDetailModal = ({show, handleClose, purchase, customer}) => {

  const { resorts, durations, domains } = useContext(ProductsContext)
  const { paymentRequests, fetchPaymentRequest } = useContext(CustomersContext)
  const [paymentId, setPaymentId] = useState(purchase?.payment_id)
  const [loadingPaymentId, setLoadingPaymentId] = useState(false)

  useEffect(() => {
    if(!purchase) return
    if(purchase.payment_id) {
      setPaymentId(purchase.payment_id)
      return
    }
    
    const paymentRequest = paymentRequests.find(pr => pr.id === purchase.id)
    if(paymentRequest) {
      setPaymentId(paymentRequest.payment_id)
    } else {
      loadPaymentRequest(purchase.id)
    }    
  }, [purchase, paymentRequests])

  const loadPaymentRequest = async (id) => {
    setLoadingPaymentId(true)
    await fetchPaymentRequest(id)
    setLoadingPaymentId(false)
  }

  const fileNameFromStorageUrl = (url, type = 'purchase') => {
    if(type === 'purchase') {
      return url.split('purchases/')[1].split('/').slice(1).join('/').split('?')[0].split('_').slice(2).join('_')
    } else if(type === 'insurance') {
      return url.split('insurance_documents/')[1].split('?')[0]
    }
    return ''
  }

  const itemWithId = (array, id) => {
    return array.find(i => i.id === id)
  }

  const handleViewDocument = (documentType) => () => {
    let url = ''
    switch(documentType) {
      case 'summary':
        url = purchase.contract_url
        break
      case 'cg':
        url = purchase.cg
        break
      case 'ipid':
        url = purchase.ipid
        break
      case 'apple-wallet-pass':
        url = purchase.apple_wallet_pass_url
        break
      case 'google-wallet-pass':
        url = purchase.google_wallet_pass_url
        break
    }
    if(!url) return alert(`${documentType} not available`)
    return window.open(url, '_blank').focus()
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >

      <div className='modal-body py-lg-10 px-lg-10'>

        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          <form noValidate id='kt_modal_create_app_form'><div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Purchase ID</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='purchase-id'
                placeholder=''
                value={purchase?.id}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Payplug payment ID</span>
              </label>
              {loadingPaymentId ?
                <div className="d-flex align-items-center">
                  <span className="spinner-border spinner-border-sm spinner-border-primary me-1"></span>
                  <span>Loading...</span>
                </div>
              : 
                <input
                  disabled
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='payment-id'
                  placeholder=''
                  value={paymentId}
                />
              }
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Product</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={purchase?.product_name}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Amount</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={`${(purchase?.amount / 100).toFixed(2)}€`}
              />
            </div>
            {purchase?.voucher_code &&
              <div className='fv-row mb-10'>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span>Voucher code</span>
                </label>
                <input
                  disabled
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  name='country-name'
                  placeholder=''
                  value={purchase?.voucher_code}
                />
              </div>
            }
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Purchase date </span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={moment(purchase?.paid_at || purchase?.meta?.updated).format("DD/MM/YYYY HH:mm")}
              />
            </div>

            { purchase?.refunded_at && <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Refund date </span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={moment(purchase.refunded_at).format("DD/MM/YYYY HH:mm")}
              />
            </div> }
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Customer email</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={purchase?.buyer?.email}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Customer name</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={`${purchase?.buyer?.firstname} ${purchase?.buyer?.lastname}`}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Insured persons</span>
              </label>
              { purchase?.people.map((person, pi) => 
                <input
                  key={`person_${pi}`}
                  disabled
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-2'
                  name='country-name'
                  placeholder=''
                  value={`${person.firstname} ${person.lastname}`}
                />)
              }
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Resort</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid mb-2'
                name='country-name'
                placeholder=''
                value={itemWithId(resorts, purchase?.resort)?.name.fr}
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Domain</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid mb-2'
                name='country-name'
                placeholder=''
                value={itemWithId(domains, purchase?.domain)?.title.fr}
              />
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Duration</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid mb-2'
                name='country-name'
                placeholder=''
                value={itemWithId(durations, purchase?.duration)?.title.fr}
              />
            </div>

            { purchase?.dates_formatted?.length > 0 && <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Dates</span>
              </label> 
              <div className="dates-wrapper">
                { purchase.dates_formatted.map((date, di) => <span key={`date_${di}`} className="date-block">{date}</span>) }
              </div>
            </div> }
            { purchase?.proofs_of_purchase?.length > 0 && <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Proofs of purchase</span>
              </label> 
              <div className="dates-wrapper">
                { purchase.proofs_of_purchase.map((url, di) => <span key={`proof_${di}`} className="date-block"><a href={url} target="_blank" rel="noreferrer">{fileNameFromStorageUrl(url, 'purchase')}</a></span>) }
              </div>
            </div> }

            <div className='d-flex flex-row-fluid justify-content-start align-items-center gap-2 gap-lg-3 mb-5'>
              <button
                type='button'
                className='btn btn-primary'
                onClick={handleViewDocument('summary')}
              >
                { 'View summary' }
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={handleViewDocument('cg')}
              >
                { 'View CG' }
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={handleViewDocument('ipid')}
              >
                { 'View IPID' }
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={handleViewDocument('apple-wallet-pass')}
              >
                { 'Download Apple Wallet Pass' }
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={handleViewDocument('google-wallet-pass')}
              >
                { 'View Google Wallet Pass' }
              </button>
            </div>
            {/* <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Name</span>
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-code'
                placeholder=''
                value={customer?.firstname ? `${customer?.firstname} ${customer?.lastname}` : customer?.displayName || 'Not specified'}
              />
            </div> */}


            {/* <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Purchases</span>
              </label>
              { purchases?.filter(p => p.user === customer?.uid).map((purchase) =>
                <div className="purchase-wrapper" key={`purchase_${purchase.id}`}>
                  { purchase.product_name }
                  { ' - ' }
                  { purchase.dates.length > 1 && `${purchase.dates.length} ${purchase.dates.length === 1 ? 'Day' : 'Days'}`}
                  { purchase.dates.length > 1 && ' - ' }
                  { `${purchase.people.length} ${purchase.people.length === 1 ? 'Person' : 'People'}`}
                  { ' - ' }
                  { `${(purchase.amount / 100).toFixed(2)}€` }
                  { ` - purchased ${new Date(purchase.meta.updated).toLocaleDateString("en-US")}` }
                  { purchase.proofs_of_purchase && purchase.proofs_of_purchase.length > 0 && <div>
                    <b><p>Proofs of purchase</p></b>
                  { purchase.proofs_of_purchase?.map((url, pi) => <a href={url} target="_blank" rel="noreferrer">{ fileNameFromStorageUrl(url, 'purchase') }<br/></a>)}
                  </div> }
                </div>
              )}
            </div> */}

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
              <div className='me-2'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default PurchaseDetailModal
