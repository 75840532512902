/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useContext, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { CustomersContext } from '../../context'
import moment from 'moment'

const modalsRoot = document.getElementById('root-modals') || document.body

const CustomerDetailModal = ({show, handleClose, customer, onPurchaseSelected}) => {
  const {
    purchases,
  } = useContext(CustomersContext)

  const [validPurchases, setValidPurchases] = useState([])

  useEffect(() => {
    let vp = []
    for(let p of purchases) {
      if(p.status === 'paid' || p.status === 'refunded') {
        vp.push(p)
      }
    }
    vp.sort((a, b) => {
      if(a.meta.created < b.meta.created) return 1
      if(a.meta.created > b.meta.created) return -1
      return 0
    })
    setValidPurchases([...vp])
  }, [purchases])

  const emailForCustomer = (customer) => {
    if(!customer) {
      return ''
    }
    if(customer.email) {
      return customer.email
    }
    const customersPurchases = validPurchases.filter(p => p.user === customer.uid)
    if(customersPurchases.length > 0) {
      return customersPurchases[0].buyer.email
    }
    return "Anonymous"
  }

  const viewPurchase = (purchase) => {
    onPurchaseSelected(purchase)
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      // onEntered={loadStepper}
      backdrop={true}
    >

      <div className='modal-body py-lg-10 px-lg-10'>

        {/*begin::Content */}
        <div className='flex-row-fluid py-lg-5 px-lg-15'>
          {/* <div className='mb-5'>
            <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
              <span>Language</span>
            </label>
            <div className='nav-group nav-group-outline nav-group-slim'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='fr'
                  checked={selectedLanguage === 'fr'}
                  onChange={(e) => setSelectedLanguage('fr')}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  FR
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  EN
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  ES
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  IT
                </span>
              </label>
            </div>
          </div> */}
          {/*begin::Form */}
          <form noValidate id='kt_modal_create_app_form'>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Email</span>
                {/* <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your unique country title'
                ></i> */}
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-name'
                placeholder=''
                value={emailForCustomer(customer)}
              />
            </div>
            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Name</span>
                {/* <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify your unique country title'
                ></i> */}
              </label>
              <input
                disabled
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='country-code'
                placeholder=''
                value={customer?.firstname ? `${customer?.firstname} ${customer?.lastname}` : customer?.displayName || 'Not specified'}
              />
            </div>


            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>Purchases</span>
              </label>
              { validPurchases?.filter(p => p.user === customer?.uid).map((purchase) =>
                <div className="purchase-wrapper" key={`purchase_${purchase.id}`} onClick={() => viewPurchase(purchase)}>
                  {/* { `(${purchase.id}) ` } */}
                  { purchase.product_name }
                  { ' - ' }
                  { purchase.dates_formatted.length > 1 && `${purchase.dates_formatted.length} ${purchase.dates_formatted.length === 1 ? 'Day' : 'Days'}`}
                  { purchase.dates_formatted.length > 1 && ' - ' }
                  { `${purchase.people.length} ${purchase.people.length === 1 ? 'Person' : 'People'}`}
                  { ' - ' }
                  { `${(purchase.amount / 100).toFixed(2)}€` }
                  { ` - purchased ${moment(purchase.paid_at || purchase.meta.updated).format('DD/MM/YYYY HH:mm')}` }
                  {/* { purchase.proofs_of_purchase && purchase.proofs_of_purchase.length > 0 && <div>
                    <b><p>Proofs of purchase</p></b>
                  { purchase.proofs_of_purchase?.map((url, pi) => <a href={url} target="_blank" rel="noreferrer">{ fileNameFromStorageUrl(url, 'purchase') }<br/></a>)}
                  </div> } */}
                  { purchase.status === 'refunded' && ` - REFUNDED ${moment(purchase.refunded_at).format('DD/MM/YYYY HH:mm')}`}
                </div>
              )}
            </div>

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3'>
              <div className='me-2'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
          </form>
          {/*end::Form */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default CustomerDetailModal
