import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc, getDoc, deleteDoc, setDoc, where, query } from "firebase/firestore"
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions"
import { now } from 'moment'
import config from "../../config.json"
import firebaseConfigs from "./firebaseConfigs.json"

const app = initializeApp(firebaseConfigs[config.environment]);

const auth = getAuth(app)
const firestore = getFirestore(app)
const functions = getFunctions(app, "europe-west1")

if(config.useFunctionsEmulator) {
  connectFunctionsEmulator(functions, "localhost", 5001)
}

const createDocument = async (collectionPath, data) => {
  const timestamp = now()
  data.meta = {
    created: timestamp,
    updated: timestamp
  }
  delete data.id
  return await addDoc(collection(firestore, collectionPath), data)
}

const updateDocument = async (documentPath, d) => {
  const timestamp = now()
  let data = { ...d }
  if(!data.meta) {
    data.meta = {
      created: timestamp,
      updated: timestamp
    }
  } else {
    data.meta.updated = timestamp
  }
  delete data.id
  let response
  try {
    // response = await setDoc(doc(firestore, documentPath), data, { merge: true })
    response = await updateDoc(doc(firestore, documentPath), data)
  } catch(err) {
    console.log("error updating document", err)
    return err
  }
  return response
}

const deleteDocument = async (documentPath) => {
  return await deleteDoc(doc(firestore, documentPath))
}


const fetchCollection = async (collectionPath, collectionQuery) => {
  if(collectionQuery) {
    return await getDocs(query(collection(firestore, collectionPath),  where(collectionQuery.field, collectionQuery.op, collectionQuery.value)))
  }
  return await getDocs(collection(firestore, collectionPath))
}

const fetchDocument = async (documentPath) => {
  return await getDoc(doc(firestore, documentPath))
}

const retrieveCustomers = async () => {
  const fetchFunction = httpsCallable(functions, "fetch_customers")
  const res = await fetchFunction()
  return res.data.customers
}

const uploadFile = async (data) => {
  const uploadFunction = httpsCallable(functions, "admin_upload_file")
  const res = await uploadFunction(data)
  return res.data
}

const createCollectionBackup = async (collectionName, environment) => {
  const backupFunction = httpsCallable(functions, "create_collection_backup")
  const res = await backupFunction({ collectionName, environment })
  return res.data
}

const importCollection = async (collectionName, sourceEnvironment, targetEnvironment, strategy) => {
  const importFunction = httpsCallable(functions, "import_collection")
  const res = await importFunction({ collectionName, sourceEnvironment, targetEnvironment, strategy })
  return res.data
}

export default app

export {
  auth,
  signInWithEmailAndPassword,
  createDocument,
  updateDocument,
  deleteDocument,
  fetchCollection,
  fetchDocument,
  retrieveCustomers,
  uploadFile,
  createCollectionBackup,
  importCollection
}
