const FileInput = ({ onFileSelected = () => { alert("onFileSelected not implemented")}, fileName = '', fileUrl = '', accept = ['pdf'], onRemove = () => { alert("onRemove not implemented") } }) => {

  const handleImageChange = (e) => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      onFileSelected({ name: file.name, type: file.type, size: file.size, base64: reader.result})
    }
    reader.readAsDataURL(file)
  }

  return (
    <div className='d-flex flex-column'>
      <div className='d-flex flex-row'>
        <div className='image-input image-input-outline image-input-empty image-input-outline-2' data-kt-image-input='true'>
          <div className={`image-input-wrapper w-300px h-60px bgi-position-y-center bgi-position-x-center bgi-size-cover d-flex justify-content-center align-items-center`}>
            { fileUrl ? <a className="image-input-url" href={fileUrl} target="_blank" rel="noreferrer">{fileName}</a> : fileName}
          </div>
          { !fileName && <label className='image-input-tooltip'>Select file</label> }
          <label className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow' data-kt-image-input-action='change' data-bs-toggle='tooltip' title='' data-bs-original-title='Change image'>
            <i className='bi bi-pencil-fill fs-7'></i>
            <input type='file' name='image' accept={accept.map(ext => `.${ext}`).join(', ')} onChange={handleImageChange} />
            <input type='hidden' name='profile_avatar_remove' />
          </label>
          { (fileUrl || fileName) && <label className='btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow' onClick={onRemove} data-kt-image-input-action='remove' data-bs-toggle='tooltip' title='' data-bs-original-title='Remove fole'>
            <i className='bi bi-trash-fill fs-7'></i>
          </label> }
        </div>
      </div>
    </div>
  )
}

export default FileInput