import React, { useReducer } from 'react'
import { NEWS, INFO_BLOCKS, FAQS } from '../../constants/collections'
import {
  createDocument as firestoreCreateDocument,
  deleteDocument as firestoreDeleteDocument,
  fetchCollection as firestoreFetchCollection,
  updateDocument as firestoreUpdateDocument
} from '../../services/firebase'
import { SET_NEWS, SET_INFO_BLOCKS, SET_FAQS } from '../types'
import contentReducer from './contentReducer'

const ContentContext = React.createContext()

const ContentState = ({ children }) => {

  const initialState = {
    news: [],
    newsLoded: false,
    infoBlocks: [],
    infoBlocksLoaded: false,
    faqs: [],
    faqsLoaded: false
  }

  const [state, dispatch] = useReducer(contentReducer, initialState)

  const COLLECTION_FOR_TYPE = {
    news: NEWS,
    infoBlocks: INFO_BLOCKS,
    faqs: FAQS,
  }

  const SETTER_FOR_TYPE = {
    news: SET_NEWS,
    infoBlocks: SET_INFO_BLOCKS,
    faqs: SET_FAQS,
  }

  const fetchCollection = async (type) => {
    const snapshot = await firestoreFetchCollection(COLLECTION_FOR_TYPE[type])
    const array = []
    snapshot.forEach(doc => {
      array.push({
        ...doc.data(),
        id: doc.id
      })
    })
    dispatch({
      type: SETTER_FOR_TYPE[type],
      payload: array
    })
  }

  const createDocument = async (type, data) => {
    try {
      const snapshot = await firestoreCreateDocument(COLLECTION_FOR_TYPE[type], data)
      dispatch({
        type: SETTER_FOR_TYPE[type],
        payload: [
          { ...data, id: snapshot.id },
          ...state[type]
        ]
      })
      return { id: snapshot.id }
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const updateDocument = async (type, id, data) => {
    try {
      await firestoreUpdateDocument(`${COLLECTION_FOR_TYPE[type]}/${id}`, data)
      let array = [...state[type]]
      let index = array.findIndex(s => s.id === id)
      array[index] = {
        ...array[index],
        ...data
      }
      dispatch({
        type: SETTER_FOR_TYPE[type],
        payload: array
      })
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  const deleteDocument = async (type, id) => {
    try {
      await firestoreDeleteDocument(`${COLLECTION_FOR_TYPE[type]}/${id}`)
      let array = [...state[type]]
      let index = array.findIndex(s => s.id === id)
      array.splice(index, 1)
      dispatch({
        type: SETTER_FOR_TYPE[type],
        payload: array
      })
      return {}
    } catch(err) {
      console.log(err)
      return { error: err }
    }
  }

  return (
    <ContentContext.Provider value={{
      news: state.news,
      newsLoaded: state.newsLoaded,
      infoBlocks: state.infoBlocks,
      infoBlocksLoaded: state.infoBlocksLoaded,
      faqs: state.faqs,
      faqsLoaded: state.faqsLoaded,
      fetchNews: () => fetchCollection('news'),
      createNews: (data) => createDocument('news', data),
      updateNews: (id, data) => updateDocument('news', id, data),
      deleteNews: (id) => deleteDocument('news', id),
      fetchInfoBlocks: () => fetchCollection('infoBlocks'),
      createInfoBlock: (data) => createDocument('infoBlocks', data),
      updateInfoBlock: (id, data) => updateDocument('infoBlocks', id, data),
      deleteInfoBlock: (id) => deleteDocument('infoBlocks', id),
      fetchFaqs: () => fetchCollection('faqs'),
      createFaq: (data) => createDocument('faqs', data),
      updateFaq: (id, data) => updateDocument('faqs', id, data),
      deleteFaq: (id) => deleteDocument('faqs', id)
    }}>
      { children }
    </ContentContext.Provider>
  )
}

export { ContentContext }

export default ContentState