/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useContext, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import { ProductsContext } from '../../context'
import { Search } from '../../../_metronic/partials'
import ImageInput from '../../components/ImageInput'
import FileInput from '../../components/FileInput'
import { uploadFile } from '../../services/firebase'
// import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css';
import config from '../../../config.json'
import moment from 'moment'

const modalsRoot = document.getElementById('root-modals') || document.body

const SpecificProductDetailModal = ({show, handleClose, product, setProduct, onSubmit, onDelete}) => {

  const { genericProducts } = useContext(ProductsContext)
  const [selectedLanguage, setSelectedLanguage] = useState('fr')
  // const [options, setOptions] = useState([])
  // const [newImage, setNewImage] = useState(null)
  // const [newCoverImage, setNewCoverImage] = useState(null)
  // const [brochure, setBrochure] = useState(null)
  const [cg, setCg] = useState(null)
  const [ipid, setIpid] = useState(null)



  const domainForEnvironment = {
    "development": "assurgliss-development.web.app",
    "staging": "assurgliss-staging.web.app",
    "production": "assuranceski.com"
  }

  useEffect(() => {
    if(!product) {
      // setNewImage(null)
      // setNewCoverImage(null)
      // setBrochure(null)
      setCg(null)
      setIpid(null)
      return
    }
  }, [product])

  const updateData = (fieldsToUpdate) => {
    const updatedData = {...product, ...fieldsToUpdate}
    setProduct(updatedData)
  }

  // const handleOptionChange = (fieldsToUpdate, index) => {
  //   const opts = options
  //   opts[index] = {
  //     ...opts[index],
  //     ...fieldsToUpdate
  //   }
  //   setOptions([...opts])
  // }

  // const handleRemoveOption = (index) => {
  //   const opts = options
  //   opts.splice(index, 1)
  //   setOptions([...opts])
  // }

  const handleSubmit = async () => {
    const data = {...product}
    // if(!data.domain || !data.duration) {
    //   alert('Please select a domain and a duration')
    //   return
    // }
    // if(newImage?.base64) {
    //   const uploadResponse = await uploadFile({ file: { name: newImage.name, type: newImage.type, size: newImage.size, base64: newImage.base64.split(',')[1]}})
    //   if(uploadResponse.url) {
    //     data.image = uploadResponse.url
    //   }
    // }
    // if(newCoverImage?.base64) {
    //   const uploadResponse = await uploadFile({ file: { name: newCoverImage.name, type: newCoverImage.type, size: newCoverImage.size, base64: newCoverImage.base64.split(',')[1]}})
    //   if(uploadResponse.url) {
    //     data.cover_image = uploadResponse.url
    //   }
    // }
    // if(brochure?.base64) {
    //   const uploadResponse = await uploadFile({ file: { name: brochure.name, type: brochure.type, size: brochure.size, base64: brochure.base64.split(',')[1]}, path: 'products/brochures'})
    //   if(uploadResponse.url) {
    //     data.brochure = uploadResponse.url
    //   }
    // }
    if(cg?.base64) {
      const uploadResponse = await uploadFile({ file: { name: cg.name, type: cg.type, size: cg.size, base64: cg.base64.split(',')[1]}, path: 'products/cg'})
      if(uploadResponse.url) {
        data.cg = uploadResponse.url
      }
    }
    if(ipid?.base64) {
      const uploadResponse = await uploadFile({ file: { name: ipid.name, type: ipid.type, size: ipid.size, base64: ipid.base64.split(',')[1]}, path: 'products/ipid'})
      if(uploadResponse.url) {
        data.ipid = uploadResponse.url
      }
    }
    onSubmit(data)
  }

  const Collapsible = ({children, collapsedContent, title, collapsedByDefault = false}) => {
    const [collapsed, setCollapsed] = useState(collapsedByDefault)
    return (
      <div className={`collapsible ${collapsed ? 'collapsed' : ''} border-top border-bottom`}>
        <button
          type='button'
          className='icon-button collapse-button'
          onClick={() => setCollapsed(!collapsed)}
        >
          <img src={`/media/icons/duotune/arrows/arr0${collapsed ? '65' : '66'}.svg`} />
        </button>
        { !collapsed ? children : collapsedContent || <div>{title}</div>}

      </div>
    )
  }

  const fileNameFromUrl = (url) => {
    if(!url) return ''
    const parts = url.split('/')
    return decodeURIComponent(parts[parts.length - 1].split('?')[0] || '')
  }

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      backdrop={true}
    >
      <div className='modal-header'>
        <div className='as-form-row align-items-center justify-content-between'>
          <h2>{ product?.id ? 'Update Specific Product' : 'Create Specific Product' }</h2>
          <div>
            <label className='mb-2'>
              <span>Language</span>
            </label>
            <div className='nav-group nav-group-outline nav-group-slim'>
              <label>
                <input
                  type='radio'
                  className='btn-check'
                  name='type'
                  value='fr'
                  checked={selectedLanguage === 'fr'}
                  onChange={(e) => setSelectedLanguage('fr')}
                />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                  FR
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='en' checked={selectedLanguage === 'en'} onChange={(e) => setSelectedLanguage('en')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  EN
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='es' checked={selectedLanguage === 'es'} onChange={(e) => setSelectedLanguage('es')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  ES
                </span>
              </label>

              <label>
                <input type='radio' className='btn-check' name='type' value='it' checked={selectedLanguage === 'it'} onChange={(e) => setSelectedLanguage('it')} />
                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                  IT
                </span>
              </label>
            </div>
          </div> 
        </div>
      </div>

      <div className='modal-body'>
        <div className='flex-row-fluid px-lg-5'>
            <div className='fv-row mb-4'>
              <label className='mb-2'>
                <span>Created: <b>{moment(product?.meta?.created || 0).format('DD/MM/YYYY HH:mm')}</b></span><br />
                <span>Updated: <b>{moment(product?.meta?.updated || 0).format('DD/MM/YYYY HH:mm')}</b></span>
              </label>
            </div>
                     
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Specific Product name</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-title'
                placeholder=''
                value={product?.name[selectedLanguage] || ''}
                onChange={(e) =>
                  updateData({
                    name: {
                      ...(product?.name || {}),
                      [selectedLanguage]: e.target.value,
                    },
                  })
                }
              />
            </div>                     
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Internal (Insurer) ID</span>
              </label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='product-internal-id'
                placeholder=''
                value={product?.internal_id || ''}
                onChange={(e) =>
                  updateData({
                    internal_id: e.target.value
                  })
                }
              />
            </div>

            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span className='required'>Generic product</span>
              </label>
              <select className='form-select form-select-solid form-select-lg' name="generic_product" id="generic_product" value={product?.generic_product || ""} onChange={e => updateData({
                  generic_product: e.target.value
                }
              )}>
                <option disabled value="">{"Select Generic Product"}</option>
                { genericProducts.map((p, pi) => 
                  <option key={`generic_product_option_${pi}`} value={p.id}>{p.name.fr}</option>
                )}
              </select>
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>CG</span>
              </label>                
              <FileInput onFileSelected={i => setCg(i)} fileUrl={product?.cg} fileName={cg?.name ? cg.name : fileNameFromUrl(product?.cg)} onRemove={() => {
                setCg(null)
                updateData({ cg: "" })
              }} />
            </div>

            <div className='fv-row mb-6'>
              <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                <span>IPID</span>
              </label>                
              <FileInput onFileSelected={i => setIpid(i)} fileUrl={product?.ipid} fileName={ipid?.name ? ipid.name : fileNameFromUrl(product?.ipid)} onRemove={() => {
                setIpid(null)
                updateData({ ipid: "" })
              }} />
            </div>
                                
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Insurer share</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-share-insurer'
                placeholder=''
                value={product?.share_insurer || 0}
                onChange={(e) =>
                  updateData({
                    share_insurer: parseFloat(e.target.value)
                  })
                }
              />
            </div>
                                
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Broker share</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-share-broker'
                placeholder=''
                value={product?.share_broker || 0}
                onChange={(e) =>
                  updateData({
                    share_broker: parseFloat(e.target.value)
                  })
                }
              />
            </div>
                                
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Co-Broker share</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-share-co-broker'
                placeholder=''
                value={product?.share_co_broker || 0}
                onChange={(e) =>
                  updateData({
                    share_co_broker: parseFloat(e.target.value)
                  })
                }
              />
            </div>
                                
            <div className='fv-row mb-6'>
              <label className='mb-2'>
                <span>Total share</span>
              </label>
              <input
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='product-share-co-broker'
                placeholder=''
                disabled
                value={(product?.share_insurer || 0) + (product?.share_broker || 0) + (product?.share_co_broker || 0)}
              />
            </div>

            {/*begin::Actions */}
            <div className='d-flex flex-row-fluid justify-content-between align-items-center gap-2 gap-lg-3 mt-10'>
              <div className='me-2'>
                { product?.id &&
                  <button
                    type='button'
                    className='btn btn-lg btn-danger'
                    data-kt-stepper-action='next'
                    onClick={onDelete}
                  >
                    { 'Delete' }
                  </button> }
              </div>                
              <div className='d-flex gap-3'>
                <button
                  type='button'
                  className='btn btn-lg'
                  data-kt-stepper-action='next'
                  onClick={handleClose}
                >
                  { 'Close' }
                </button>
                <button
                  type='button'
                  className='btn btn-lg btn-primary'
                  data-kt-stepper-action='next'
                  onClick={handleSubmit}
                >
                  { product?.id ? 'Update' : 'Create' }
                </button>
              </div>
            </div>
            {/*end::Actions */}
        </div>
        {/*end::Content */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export default SpecificProductDetailModal
